import React, {useState} from "react";
import css from './BasicSelect.module.scss';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    fontFamily: 'Bader-compress',
    padding: '10px 25px 10px 15px',
    fontSize: 20,
    cursor: 'pointer',
    borderBottom: '1px solid #dcdcdc',
    backgroundColor: 'unset',
    backgroundImage: state.isSelected ? 'url("/static/images/pictos/icon-check-primary.svg")': '',
    backgroundSize: '15px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 5px top 17px',
    color: 'black',
    "&:hover": {
      backgroundColor: "#f4f4f4"
    }
  }),
  control: (provided) => ({
    ...provided,
    fontSize: 16,
    fontFamily: 'Bader-narrow',
    border: '0 !important',
    boxShadow: '0 !important',
  }),
  menu: () => ({
    border: 0
  }),
  singleValue: (provided) => {
    const position = 'unset';
    const top = 'unset';
    const maxWidth = 'unset';
    const transform = 'unset';
    return { ...provided, position, top, maxWidth, transform };
  },
  menuList: (base) => ({
    ...base,
    height: 200,
    position: 'absolute',
    background: 'white',
    border: 0,
    zIndex: 999,
    paddingBottom: 0,
    boxShadow: '4px 6px 40px 0 rgb(0 0 0 / 10%)',
    "::-webkit-scrollbar": {
      width: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#f0b856",
      borderRadius: "4px"
    }
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '0',
    margin: '0'
  }),
}

const BasicSelect = (props) => {

  if (props.value === null || props.value === undefined) {
    props.onChange && props.onChange(props.options[0].value, props.inputName);
  }

  const findValue = (val) => {
    const res = props.options.filter(opt => opt.value == val);

    if (!res || !res.length) {
      return null;
    }

    return res[0];
  };

  const [oldValue, setOldValue] = useState(findValue(props.value) || props.options[0]);

  return (<div className={`${css.input__wrapper} ${props.customClass}`}>
        <label className={css.label}>
          {props.label}
        </label>
        <Select   components={{
                    IndicatorSeparator: () => null
                  }}
                  styles={customStyles}
                  isSearchable={false}
                  options={props.options}
                  onChange={(newValue) => {
                    if (!props.onChange || props.onChange(newValue, oldValue)) {
                      setOldValue(newValue);
                    }
                  }}
                  value={findValue(props.value) || props.options[0]}
                  placeholder="Select an option"
                  className={props.customSelect}
        />
        {(props.errors && props.errors[props.inputName]) && <div
          className={css.input__errors}>
          <p>{props.errors[props.inputName]}</p>
        </div>}
      </div>);
};

export default BasicSelect;
