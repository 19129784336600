import React from 'react';
import css from './ListTitle.module.scss';

const ListTitle = ({title, subtitle, align, size, subSize, customClassTitle, customClassSubtitle}) => {

    return (
        <h2 className={`${css.title} ${customClassTitle}`}
            style={{fontSize: size, textAlign: align}}
        >
            {title}
            <span className={customClassSubtitle}
                  style={{fontSize: subSize}}
            >{subtitle}</span>
        </h2>
    );
};

export default ListTitle;
