import React from 'react';
import css from "./Spinner.module.scss";

const Spinner = ({customClass, dark = false}) => {
    const spinnerImagePath = dark ? "/static/images/pictos/icon-loader.svg" : "/static/images/pictos/icon-loader-white.svg"
    return (
        <>
            <img className={`${css.spinner} ${customClass ? customClass : ''}  `}
                 src={spinnerImagePath}
                 alt={'Loading...'}
            />
        </>
    );
};

export default Spinner;
