import React from 'react';
import PropTypes from 'prop-types';
import css from './_navlink.module.scss';

const Navlink = (props) => {
  return (
    <a href={props.url} className={`${css.navlink} ${props.active ? css.active : ""} ${props.customClass ? props.customClass : ""}`}><span>{props.name}</span></a>
  );
};

Navlink.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string
};

Navlink.defaultProps = {
  url: "/",
  name: "navLink"
};

export default Navlink;
