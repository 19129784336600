/**
 * PotsService
 * @module Services/domain/ProductsService
 * @description Offers a set of methods to help performing products related actions
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {ProductType} from "@/models/Product";


const self = {};

/**
 * Finds products within criteria
 * @author SEIF KHIARI
 * @alias find
 * @param criteria {object} search criteria
 * @param limit {number} search limit
 * @param offset {number} search offset
 * @param ctx {object} context
 * @memberof module:Services/domain/ProductsService
 * @returns {promise}  A promise containing the resulting products
 */
self.find = (criteria, limit, offset, ctx) => {
  let searchCriteria = {
    gender: criteria.gender,
    type: criteria.type || ProductType.MARRIAGE,
    categories: criteria.categories,
    sub_categories: criteria.sub_categories,
    brands: criteria.brands,
    age_ranges: criteria.age_ranges,
    min_price: criteria.min_price,
    max_price: criteria.max_price,
    sort_criteria: criteria.sort_criteria,
    is_favorite: criteria.is_favorite,
    inspiration_list: criteria.inspiration_list,
    limit: limit,
    offset: offset * limit,
  };
  return HttpService(ctx).get(LinksProvider.getRouteWithQuery(LinksProvider.API.PRODUCTS.SEARCH, searchCriteria))
    .then(result => result);
};

/**
 * get product
 * @author SEIF KHIARI
 * @alias get
 * @param reference {string} product reference
 * @param ctx {object} context
 * @memberof module:Services/domain/ProductsService
 * @returns {promise}  A promise containing product
 */
self.get = (reference, ctx) => {
  return HttpService(ctx).get(LinksProvider.getRouteWithQuery(LinksProvider.API.PRODUCTS.PRODUCT, {reference}))
    .then(result => result.product);
};


/**
 * Finds products within criteria
 * @author SEIF KHIARI
 * @alias find
 * @param criteria {object} search criteria
 * @param limit {number} search limit
 * @param offset {number} search offset
 * @param ctx {object} context
 * @memberof module:Services/domain/ProductsService
 * @returns {promise}  A promise containing the resulting products
 */
self.findBrands = (criteria, limit, offset, ctx) => {
  let searchCriteria = {
    gender: criteria.gender,
    type: criteria.type || ProductType.MARRIAGE,
    categories: criteria.categories,
    age_ranges: criteria.age_ranges,
    min_price: criteria.min_price,
    max_price: criteria.max_price,
    sort_criteria: criteria.sort_criteria,
    is_favorite: criteria.is_favorite,
    inspiration_list: criteria.inspiration_list,
    limit: limit,
    offset: offset * limit
  };
  return HttpService(ctx).get(LinksProvider.getRouteWithQuery(LinksProvider.API.PRODUCTS.BRANDS, searchCriteria));
};


export default self;
