/**
 * PromoCodesService
 * @module Services/domain/PromoCodesService
 * @description Offers a set of methods to help promo codes related actions
 */


import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

const self = {};


/**
 * Gets a promo code
 * @author Sameh Bellez
 * @alias get
 * @param reference {string} The promo code reference
 * @memberof module:Services/domain/PromoCodesService
 * @returns {promise}  A promise containing the retrieval result
 */
self.get = (reference) => {
  return HttpService().get(LinksProvider.getFull(LinksProvider.API.PROMO_CODES.PROMO_CODE, {reference: reference}))
    .then(result => result.promo_code);
};

/**
 * Gets sponsorship details
 * @author Hassen Charef
 * @alias get
 * @memberof module:Services/domain/PromoCodesService
 * @returns {promise}  A promise containing the retrieval result
 */
self.getSponsorshipDetails = () => {
  return HttpService().get(LinksProvider.getFull(LinksProvider.API.PROMO_CODES.SPONSORSHIP_DETAILS))
    .then(result => result.sponsorship_details);
};


export default self;
