import React from "react";
import {DefaultToastContainer} from 'react-toast-notifications';
import css from "./ToastContainer.module.scss"

const ToastContainer = ({...props}) => {
    return (<DefaultToastContainer {...props} className={css.toast_container}>
        {props.children}
    </DefaultToastContainer>);
};

export default ToastContainer;
