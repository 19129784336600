/**
 * PotsService
 * @module Services/domain/ProductsService
 * @description Offers a set of methods to help performing products related actions
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";


const self = {};

/**
 * find wedding list(s) according to multiple search criteria
 * @author Antoine Sauvage
 * @alias find
 * @param criteria {object} search criteria
 * @param limit {number} search limit
 * @param offset {number} search offset
 * @memberof module:Services/domain/WeddingListsService
 * @returns {promise}  find wedding list(s) according to multiple search criteria
 */
self.find = (criteria, limit, offset, ctx) => {
  let searchCriteria = {
    searchKey: criteria,
    limit: limit,
    offset: offset * limit
  };
  return HttpService(ctx).get(LinksProvider.getRouteWithQuery(LinksProvider.API.LISTS.SEARCH, searchCriteria))
    .then(result => {
      if (!criteria) {
        return [];
      } else {
        return result;
      }
    });
};

/**
 * get list
 * @author Antoine Sauvage
 * @alias get
 * @param reference {string} list reference
 * @param ctx {object} context
 * @memberof module:Services/domain/ProductsService
 * @returns {promise}  A promise containing product
 */
self.getList = (reference, ctx) => {
  return HttpService(ctx).get(LinksProvider.getRouteWithQuery(LinksProvider.API.LISTS.GET_LIST, {reference: reference}))
    .then(result => result.pot);
};

/**
 * get list products
 * @api {get} /lists/products Returns wedding list products
 * @apiName getWeddingListProducts
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Returns wedding list products
 */

self.getListProducts = () => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.LISTS.LIST_PRODUCTS))
    .then(result => result.products);
};

/**
 * get list products by ref
 * @api {get} /lists/:reference/products Returns wedding list products
 * @apiName getWeddingListProducts
 * @apiGroup WeddingListProduct
 *  @param reference {string} list ref
 * @apiDescription  Returns wedding list products by ref
 */
self.getListProductsByRef = (reference) => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.LISTS.GET_LIST_PRODUCTS_BY_REF, {reference: reference}))
    .then(result => result.products);
};

/**
 * @api {post} /lists/products/link Links product to list
 * @apiName addListProduct
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Links product to list
 *
 * @apiParam {string} reference reference of product to be added to list
 */


self.addListProduct = (reference, quantity = 1) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.LISTS.ADD_LIST_PRODUCT), {reference: reference, quantity: quantity})
    .then(result => result);
};

/**
 * @api {post} /lists/products Links product to list
 * @apiName addCustomListProduct
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Creates custom product and links it to list
 *
 * @apiParam {string} reference reference of product to be added to list
 */


self.addCustomListProduct = (product, is_vdm = false) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.LISTS.LIST_PRODUCTS), {...product, is_vdm})
    .then(result => result);
};

/**
 * @api {put} /lists/products/:reference Updates a product
 * @apiName updateCustomListProduct
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Updates custom product
 *
 * @apiParam {string} reference reference of product to be updates to list
 */


self.updateCustomListProduct = (product, is_vdm = false) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.LISTS.CUSTOM_LIST_PRODUCT, {reference: product.reference}), {...product, is_vdm})
    .then(result => {
      return result.product;
    });
};

/**
 * @api {post} /lists/products/unlink Unlinks product to list
 * @apiName deleteListProduct
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Unlinks product to list
 *
 * @apiParam {string} reference reference of product to be unlinked from list
 */

self.removeListProduct = (reference) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.LISTS.REMOVE_LIST_PRODUCT), {reference: reference})
    .then(result => result);
};


self.updateListProduct = (reference, productReference, quantity) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.LISTS.UPDATE_LIST_PRODUCT, {reference, productReference}), {quantity: quantity})
}

/**
 * @api {get} /lists/:reference/products/:productReference/participations Returns wedding list products
 * @apiName getWeddingListProductParticipants
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Returns wedding list product participants
 * */


self.getListProductParticipants = (request) => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.LISTS.GET_LIST_PRODUCT_PARTICIPANTS, {
    reference: request.reference, productReference: request.productReference
  }))
    .then(result => result.participants);
};


/**
 * @api {post} /lists/validate-url validate personalised url
 * @apiName resendListSpouseConfirmation
 * @apiGroup WeddingListPot
 * @apiVersion 0.0.0
 * @apiDescription Resends list spouse confirmation email
 * @apiParam {string} reference url to validate
 */
self.resendListSpouseConfirmation = (reference) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.LISTS.RESEND_SPOUSE_CONFIRMATION, {reference: reference}))
    .then(result => result);
};

/**
 * @api {get} /lists/:reference/list-validation-progress fetches a list's validation progress
 * @apiName getListValidationProgress
 * @apiGroup WeddingListUser
 * @apiVersion 0.0.0
 * @apiDescription  Fetches a list's validation progress
 */

self.getListValidationProgress = (reference) => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.LISTS.GET_ACCOUNT_VALIDATION_PROGRESS, {reference: reference}))
    .then(result => result.pot);
};

/**
 * add inspiration list products to active list
 * @author Seif Khiari
 * @alias addInspirationListProducts
 * @param reference {string} inspiartion list reference
 * @memberof module:Services/domain/WeddingListsService
 * @returns {promise}  A promise containing product
 */
self.addInspirationListProducts = (reference, criteria) => {
  let searchCriteria = {
    is_favorite: criteria.favorite || undefined,
    categories: criteria.categories || undefined,
    age_ranges: criteria.age || undefined,
    min_price: criteria.min_price || undefined,
    max_price: criteria.max_price || undefined,
    gender: criteria.max_price || undefined,
    type: criteria.type || undefined,
  };
  return HttpService().post(LinksProvider.get(LinksProvider.API.LISTS.ADD_INSPIRATION_LIST_PRODUCTS, {reference: reference}), searchCriteria)
    .then(result => result.pot);
};

/**
 * @api {post} /lists/:reference/vdm-estimate Adds a VDM estimate to a list
 * @apiName addVDMEstimate
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Adds a VDM estimate to a list
 *
 * @apiParam {string} reference reference of the list
 * @apiParam {object} values the vdm estimate's field values
 */


self.addVDMEstimate = (reference, values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.LISTS.VDM_ESTIMATE, {reference: reference}), values)
    .then(result => result);
};


/**
 * @api {post} /lists/:reference/products/reorganize Reorganize
 * @apiName reorganizeWeddingListProducts
 * @apiGroup WeddingListProduct
 * @apiVersion 0.0.0
 * @apiDescription  Reorganize wedding list products
 *
 * @apiParam {string} reference reference of the list
 * @apiParam {object} values the vdm estimate's field values
 */

self.reorganizeWeddingListProducts = (reference, productsOrder) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.LISTS.REORGANIZE_LIST_PRODUCTS, {reference: reference}), {potProducts : productsOrder})
    .then(result => result);
}



export default self;
