import React from "react";
import Link from "next/link";
import { withTranslation } from "../../../../i18n";
import ListTitle from "../../../../shared/textes/ListTitle/ListTitle";
import Button from "../../../../shared/button/simpleButton/Button";
import css from "./HomeThemes.module.scss";
import LinksProvider from "@/services/http/LinksProvider";
import { CommercialPartnerCategory } from "@/models/CommercialPartner";
import parse from "react-render-html";

const namespacesRequired = ["home"];

const HomeThemes = ({ t }) => {
  const brands = [
    {
      img: "/static/images/home/themes/theme-00.jpg",
      alt: t("home:themes.desc-00"),
      desc: t("home:themes.desc-00"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.VENUES,
    },
    {
      img: "/static/images/home/themes/theme-01.jpg",
      alt: t("home:themes.desc-01"),
      desc: t("home:themes.desc-01"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.CATERERS,
    },
    {
      img: "/static/images/home/themes/theme-02.jpg",
      alt: t("home:themes.desc-02"),
      desc: t("home:themes.desc-02"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.OUTFITS_ACCESSORIES,
    },
    {
      img: "/static/images/home/themes/theme-03.jpg",
      alt: t("home:themes.desc-03"),
      desc: t("home:themes.desc-03"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.MARRIAGE_DATE_PARTNERS,
    },
    {
      img: "/static/images/home/themes/theme-04.jpg",
      alt: t("home:themes.desc-04"),
      desc: t("home:themes.desc-04"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.PHOTOGRAPHERS_VIDEOGRAPHERS,
    },
    {
      img: "/static/images/home/themes/theme-05.jpg",
      alt: t("home:themes.desc-05"),
      desc: t("home:themes.desc-05"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.GUESTS,
    },
    {
      img: "/static/images/home/themes/theme-06.jpg",
      alt: t("home:themes.desc-06"),
      desc: t("home:themes.desc-06"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.HONEYMOON,
    },
    {
      img: "/static/images/home/themes/theme-07.jpg",
      alt: t("home:themes.desc-07"),
      desc: t("home:themes.desc-07"),
      link:
        LinksProvider.get(LinksProvider.ROUTES.PARTNERS) +
        "?category=" +
        CommercialPartnerCategory.OTHER,
    },
  ];
  return (
    <div className={css.wrapper}>
      <div className="flex justify-center">
        <ListTitle
          title={<div>{t("home:themes.title")}</div>}
          subtitle={t("home:themes.subtitle")}
        />
        <img
          src="/static/images/themes/adresse.svg"
          height={72}
          width={72}
          style={{ transform: "translateY(-15px)" }}
        />
      </div>

      <p className="t-center mb-24">{parse(t("home:themes.description"))}</p>

      <div className={css.themes}>
        {brands.map((brand, i) => {
          return (
            <Link href={brand.link} key={i}>
              <a className={css.theme} target="_blank">
                <div
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), url(${brand.img}) `,
                  }}
                >
                  <h2 dangerouslySetInnerHTML={{ __html: brand.desc }} />
                </div>
              </a>
            </Link>
          );
        })}
      </div>
      <div className={css.btn_container}>
        <Link href={LinksProvider.get(LinksProvider.ROUTES.PARTNERS)}>
          <Button className={css.btn_light}>
            {t("home:themes.show-themes")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default withTranslation(namespacesRequired)(HomeThemes);
