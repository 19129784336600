import * as Sentry from "@sentry/nextjs";

const packageJson = require('./package.json');

Sentry.init({
  dsn: process.env.NEXT_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: packageJson.version,
  debug: true,
  attachStacktrace: true,
  enabled: process.env.SENTRY_ENABLE
});
