/**
 * RequestInterceptor
 * @module Services/http/RequestInterceptor
 * @description Returns a RequestInterceptor function that'll intercept every http request
 */

import AuthService from '../domain/AuthService';

/**
 * Intercepts every http request and adds the jwt access token to the header
 * @author Sameh Bellez
 * @alias RequestInterceptor
 * @memberof module:Services/http/RequestInterceptor
 * @param   {object} config The request config
 * @returns {object}  The request config
 */
function RequestInterceptor(ctx) {
  return (config) => {
    config.keepOriginalResponse = config.headers['Keep-Original-Response'];
    config.headers['Content-Type'] = 'application/json';
    config.headers['Origin-App'] = 'M1L';
    AuthService.isAuthenticated()
    && (config.headers['Authorization'] = `Bearer ${AuthService.getToken()}`);
    (ctx && ctx.req)
    && (config.headers['Authorization'] = `Bearer ${AuthService.getServerToken(ctx)}`);
    (ctx && ctx.req && ctx.req.ip)
    && (config.headers['X-Client-Ip'] = ctx.req.ip);
    return config;
  };
}

module.exports = RequestInterceptor;
