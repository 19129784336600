const UserReducer = (state, action) => {
    switch (action.type) {
        case 'GET_ACTIVE_LIST_START':
            return {
                ...state,
                isFetching: true
            };
        case 'GET_ACTIVE_LIST_SUCCESS':
            return {
                ...state,
                activeList: action.payload,
                isFetching: false
            };
        case 'GET_ACTIVE_LIST_FAILURE':
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            };
        default:
            return state;
    }
};


export default UserReducer;

