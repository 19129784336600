import React from 'react';
import ButtonSpinner from "../../common/button-spinner/ButtonSpinner";
import css from "./Button.module.scss";

const Button = (props) => {
  return (<button
    className={`${props.className} ${props.customClass} ${props.loading ? css.button_loading : ''}`}
    type={props.type}
    disabled={props.disabled || props.loading}
    onClick={props.onClick}>
    {props.children}
    <ButtonSpinner loading={props.loading}/>
  </button>)
};

export default Button;
