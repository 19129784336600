import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import css from './_navbrand.module.scss';

const Navbrand = (props) => {
  return (
    <Link href="/">
      <a className={`${css.nav__brand} ${props.styleOverwrite}`}>
        <img src={props.imgSrc} alt={props.imgDesc}/>
      </a>
    </Link>
  );
};

Navbrand.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgDesc: PropTypes.string
};

Navbrand.defaultProps = {
  imgSrc: "/static/images/logos/logo.png",
  imgDesc: "Img Desc for accessibility  purpose"
};

export default Navbrand;
