/**
 * ResponseHandler
 * @module Services/http/ResponseHandler
 * @description Handlers a http response
 */

import ErrorHandler from '../error/ErrorHandler';
import AuthService from '../domain/AuthService';

const self = {};

/**
 * Handlers every successful http request and returns its data
 * @author Sameh Bellez
 * @alias handleSuccess
 * @memberof module:Services/http/ResponseHandler
 * @param   {object} ctx The context
 * @param   {object} response The http response
 * @returns {object}  The response data
 */
self.handleSuccess = (ctx) => (response) => {
  if (response.headers['access-token']) {
    AuthService.saveAuthToken(response.headers['access-token'], false, ctx);
  }
  if (response.headers['access-kyc-token']) {
    AuthService.saveKycToken(response.headers['access-kyc-token'], ctx);
  }
  if (response.config.keepOriginalResponse) {
    return response;
  }
  return response.data.data || response.data;
};

/**
 * Handlers every failed http request, handles the errors and reject the error
 * @author Sameh Bellez
 * @alias handleError
 * @memberof module:Services/http/ResponseHandler
 * @param   {object} error The http error
 * @returns {promise}  A promise rejecting the error
 */
self.handleError = (error) => {
  const response = {...error}.response;

  if (response && response.data && response.data.data && response.data.data.errors) {
    ErrorHandler.handleErrors(response.data.data.errors);
  }

  return Promise.reject(response && response.data ? response.data : error);
};

module.exports = self;
