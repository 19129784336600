import React from "react";
import css from './Notification.module.scss';

const Notification = ({children, customClass}) => {
  return (
    <div className={`${css.notification} ${customClass ? customClass : ''}`}>
      {children}
    </div>
  );
};

export default Notification;
