/**
 * PublicationsService
 * @module Services/domain/PublicationsService
 * @description Offers a set of methods to help performing publications related actions
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";
import {formatUrl} from "@/shared/list/utils/utils";

const self = {};

/**
 * Finds publications within criteria
 * @author SEIF KHIARI
 * @alias find
 * @param searchKey {string} search key
 * @param category {number} search category
 * @param template {number} search template
 * @param limit {number} search limit
 * @param offset {number} search offset
 * @param ignoreCategoryHeadline {boolean} whether or not to ignore the category headline
 * @param ctx {object} context
 * @memberof module:Services/domain/PublicationsService
 * @returns {promise}  A promise containing the resulting publications
 */
self.find = (searchKey, category, template, limit, offset, ignoreCategoryHeadline, ctx) => {
  let searchCriteria = {
    searchKey: searchKey || undefined,
    category: category || undefined,
    template: template || undefined,
    limit: limit,
    offset: offset,
    ignoreCategoryHeadline: ignoreCategoryHeadline || undefined
  };
  return HttpService(ctx).get(LinksProvider.getRouteWithQuery(LinksProvider.API.PUBLICATIONS.SEARCH, searchCriteria))
    .then(result => result);
};

/**
 * get publication
 * @author SEIF KHIARI
 * @alias get
 * @param reference {string} publication reference
 * @param ctx {object} context
 * @memberof module:Services/domain/PublicationsService
 * @returns {promise}  A promise containing publication
 */
self.get = (reference, ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.PUBLICATIONS.PUBLICATION, {reference}))
    .then(result => result.publication);
};

/**
 * get top three publications
 * @author SEIF KHIARI
 * @alias getTopThree
 * @param ctx {object} context
 * @memberof module:Services/domain/PublicationsService
 * @returns {promise}  A promise containing publications
 */
self.getTop = (limit, ctx) => {
  console.log('****', LinksProvider.getRouteWithQuery(LinksProvider.API.PUBLICATIONS.TOP, {limit}), limit)
  return HttpService(ctx).get(LinksProvider.getRouteWithQuery(LinksProvider.API.PUBLICATIONS.TOP, {limit}))
    .then(result => result.publications);
};

/**
 * Get the headline or the most recent publication for a specific category
 * @author Ghassen Manai
 * @alias getCategoryHeadline
 * @param category {number} search category
 * @param ctx {object} context
 * @memberof module:Services/domain/PublicationsService
 * @returns {promise}  A promise containing publications
 */
self.getCategoryHeadline = (category, ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.PUBLICATIONS.GET_CATEGORY_HEADLINE, {category: category}))
    .then(result => result.publication);
};

/**
 * Get publication redirection URL
 * @author Ahmed TOUAITI
 * @alias getPublicationLink
 * @param publication {object} the publication object category
 * @memberof module:Services/domain/PublicationsService
 * @returns {string} A publication redirection URL
 */
self.getPublicationLink = (publication, t) => {
  return LinksProvider.get(LinksProvider.ROUTES.PUBLICATION.ONE, {
      category: formatUrl(
          t("publications:publication.category-" + publication.category)
      ),
      reference: publication.reference + '-' + formatUrl(publication.title),
  });
};


export default self;
