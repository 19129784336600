import { useState } from "react";
import css from "./ExpandedText.module.scss";
import { useTranslation } from "../../i18n";

const ExpandedText = ({ text, maxLength = 100 }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={css.text}>
      <p
        dangerouslySetInnerHTML={{
          __html: isExpanded ? text : `${text.slice(0, maxLength)}...`,
        }}
        className={css.text__content}
      />
      {text.length > maxLength && (
        <>
          &nbsp;
          <a onClick={toggleExpansion} className={css.text__content__showMore}>
            {isExpanded ? t("home:guide.see-less") : t("home:guide.see-more")}
          </a>
        </>
      )}
    </div>
  );
};

export default ExpandedText;
