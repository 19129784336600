import React, {useReducer} from 'react';
import CartContext from './CartContext';
import cartReducer from './CartReducer';


const CartProvider = props => {

    const initialState = {
        cartItems: [],
    };


    const [state, dispatch] = useReducer(cartReducer, initialState);


    const addLocalStorageToState = (cart) => {
        dispatch({type: 'ADD_LOCAL_STORAGE_TO_STATE', payload: cart});
    }

    const addCartItem = (item, participationAmount) => {
        dispatch({type: 'ADD_CART_ITEM', payload: item, participationAmount: participationAmount});
    }

    const removeCartItem = (item) => {
        dispatch({type: 'REMOVE_CART_ITEM', payload: item.reference});
    }
    
    const removeAllCartItems = () => {
        state?.cartItems?.forEach(item => {
            dispatch({type: 'REMOVE_CART_ITEM', payload: item.reference});
        });
    }


    return (
        <CartContext.Provider
            value={{
                cartItems: state.cartItems,
                addCartItem: addCartItem,
                removeCartItem: removeCartItem,
                addLocalStorageToState: addLocalStorageToState,
                removeAllCartItems: removeAllCartItems
            }}
        >
            {props.children}
        </CartContext.Provider>
    )
};

export default CartProvider;