/**
 * ErrorHandler
 * @module Services/error/ErrorHandler
 * @description services for ErrorHandler
 */

import cookies from 'js-cookie';
import nextCookies from 'next-cookies';
import {setCookie} from 'nookies';
import DomService from "@/services/utils/DomService";

const self = {};

self.COOKIE_ERROR_MESSAGES_TAG = 'error_messages';
self.COOKIE_SUCCESS_MESSAGES_TAG = 'success_messages';

/**
 * Transforms a Yup validation error to a more specific error prefixed with E_ and post-fixed with
 * _{field_name}
 * @author Sameh Bellez
 * @alias normalizeOneValidationError
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} field The field of the error
 * @param   {string} error the error
 * @returns {string}  a more specific error
 */
const normalizeOneValidationError = (field, error) => {
  if (error.indexOf("E_") == 0) {
    return error;
  }
  return 'E_' + error.toUpperCase() + '_' + field.toUpperCase();
};

/**
 * Transforms a set Yup validation errors to a more specific errors prefixed with E_ and post-fixed
 * with
 * _{field_name}
 * @author Sameh Bellez
 * @alias normalizeValidationErrors
 * @memberof module:Services/error/ErrorHandler
 * @param   {array} errors The set of errors
 * @returns {array}  a set of more specific errors
 */
self.normalizeValidationErrors = (errors) => {
  if (!errors) {
    return;
  }
  for (let field in errors) {
    errors[field] = normalizeOneValidationError(field, errors[field]);
  }
  return {errors: errors};
};

/**
 * Sets the errorAction field
 * @author Sameh Bellez
 * @alias setErrorActions
 * @memberof module:Services/error/ErrorHandler
 * @param   {Object} actions The actions
 * @returns {void}
 */
self.setErrorActions = (actions) => {
  self.errorActions = actions;
};

/**
 * Handles a set of errors and sets them to the errorActions field
 * @author Sameh Bellez
 * @alias handleErrors
 * @memberof module:Services/error/ErrorHandler
 * @param   {arrays} errors The errors
 * @returns {void}
 */
self.handleErrors = (errors) => {
  if (!errors || !errors.length || !self.errorActions) {
    return;
  }

  self.errorActions.setStatus(null);
  const status = {};
  const genericErrors = [];
  errors.forEach(error => {
    if (error.field) {
      status[error.field] = error.code;
    } else {
      genericErrors.push(error.code);
    }
  });

  if (genericErrors.length) {
    status.genericErrors = genericErrors;
  }
  self.errorActions.setStatus(status);
};

/**
 * Adds a cookie message
 * @author Sameh Bellez
 * @alias addCookieMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} tag the message tag
 * @param   {string} message The message to save
 * @param   {Object} ctx the http context
 * @returns {void}
 */
const addCookieMessage = (tag, message, ctx) => {
  let cookieMessages;
  if (ctx) {
    cookieMessages = nextCookies(ctx)[tag];
  } else {
    cookieMessages = cookies.get(tag);
  }

  let messages;
  if (cookieMessages) {
    messages = JSON.parse(cookieMessages);
  } else {
    messages = [];
  }

  messages.push(message);
  if (ctx) {
    const maxAge = 60 * 60 * 24 * 365;
    setCookie(ctx, tag, JSON.stringify(messages), {
      path: '/',
      maxAge
    });
  } else {
    cookies.set(tag, JSON.stringify(messages));
  }
};

/**
 * Adds a cookie error message
 * @author Sameh Bellez
 * @alias addCookieErrorMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} message The message to save
 * @param   {Object} ctx the http context
 * @returns {void}
 */
self.addCookieErrorMessage = (message, ctx) => {
  addCookieMessage(self.COOKIE_ERROR_MESSAGES_TAG, message, ctx);
};

/**
 * Adds a cookie success message
 * @author Sameh Bellez
 * @alias addCookieSuccessMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} message The message to save
 * @param   {Object} ctx the http context
 * @returns {void}
 */
self.addCookieSuccessMessage = (message, ctx) => {
  addCookieMessage(self.COOKIE_SUCCESS_MESSAGES_TAG, message, ctx);
};

/**
 * Gets cookies error messages
 * @author Sameh Bellez
 * @alias addCookieSuccessMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} tag The cookie tag
 * @param   {object} ctx the http context
 * @returns {void}
 */
const getCookieMessages = (tag, ctx) => {
  if (!ctx) {
    return null;
  }
  const messages = nextCookies(ctx)[tag];
  if (!messages) {
    return null;
  }

  return JSON.parse(messages);
};

/**
 * Gets cookies error messages
 * @author Sameh Bellez
 * @alias getCookieErrorMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {object} ctx the http context
 * @returns {void}
 */
self.getCookieErrorMessages = (ctx) => {
  return getCookieMessages(self.COOKIE_ERROR_MESSAGES_TAG, ctx);
};

/**
 * Gets cookies success messages
 * @author Sameh Bellez
 * @alias getCookieSuccessMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {object} ctx the http context
 * @returns {void}
 */
self.getCookieSuccessMessages = (ctx) => {
  return getCookieMessages(self.COOKIE_SUCCESS_MESSAGES_TAG, ctx);
};

/**
 * Displays a cookie message
 * @author Sameh Bellez
 * @alias displayCookieMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} tag The cookie tag
 * @param   {string} type The messages type
 * @param   {array} messages The messages to display
 * @param   {function} addToast The toast display function
 * @param   {function} t Translations
 * @returns {void}
 */
const displayCookieMessages = (tag, type, messages, addToast, t) => {
  if (messages && messages.length) {
    messages.forEach((message) => {
      addToast(t(message), {
        appearance: type, autoDismiss: true
      });
    });
  }
  cookies.remove(tag);
};

/**
 * Displays a cookie error message
 * @author Sameh Bellez
 * @alias displayCookieErrorMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {array} messages The messages to display
 * @param   {function} addToast The toast display function
 * @param   {function} t Translations
 * @returns {void}
 */
self.displayCookieErrorMessages = (messages, addToast, t) => {
  displayCookieMessages(self.COOKIE_ERROR_MESSAGES_TAG, 'error', messages, addToast, t);
};

/**
 * Displays a cookie success message
 * @author Sameh Bellez
 * @alias displayCookieSuccessMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {array} messages The messages to display
 * @param   {function} addToast The toast display function
 * @param   {function} t Translations
 * @returns {void}
 */
self.displayCookieSuccessMessages = (messages, addToast, t) => {
  displayCookieMessages(self.COOKIE_SUCCESS_MESSAGES_TAG, 'success', messages, addToast, t);
};

/**
 * Adds a toast for backend api errors
 * @author Ghassen Manai
 * @alias toastErrorCodeMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {object} error The error object
 * @param   {function} addToast The toast display function
 * @param   {function} t Translations
 * @returns {void}
 */
self.toastErrorCodeMessage = (error, addToast, t) => {

  const toastGeneralError = () => {
    return addToast(t(`common:errors.GENERAL_ERROR`), {
      appearance: 'error', autoDismiss: true
    });
  };

  if (!error || !error.data) {
    toastGeneralError();
    return;
  }

  if(!error.data.errors || !error.data.errors.length) {
    if(!error.data.code) {
      toastGeneralError();
      return;
    }
    error.data.errors = [{code: error.data.code, message: (error.data.message ? error.data.message : "No Message")}];
  }

  error.data.errors.forEach(err => {
    addToast(t(`common:errors.${err.code}`), {
      appearance: 'error', autoDismiss: false
    });
  });
};

/**
 * Handles form error scroll behavior
 * @author Seif Khiari
 * @alias handleErrorsScroll
 * @memberof module:Services/error/ErrorHandler
 * @param   {object} refs object containing refs to form inputs/components
 * @param   {number} offset offset of scroll
 * @returns {void}
 */
self.handleErrorsScroll = (refs, offset) => {
  return (errors) => {
    if (errors) {
      const keys = Object.keys(errors);
      if (keys && keys.length) {
        for (let ref_key in refs) {
          if (errors.hasOwnProperty(ref_key)) {
            const ref = refs[ref_key];
            DomService.scrollToRef(ref, offset);
            if (ref.current.focus && typeof ref.current.focus === 'function') {
              ref.current.focus();
            }
            break;
          }
        }
      }
    }
  }
};

/**
 * Checks if form erros have required errors
 * @author Seif Khiari
 * @alias formHasRequiredError
 * @memberof module:Services/error/ErrorHandler
 * @param   {object} errors object containing form errors
 * @returns {boolean}
 */
self.formHasRequiredError = (errors) => {
  return Object.values(errors).some(e => e.includes('E_REQUIRED_'))
};

module.exports = self;
