import React, {useState} from "react";
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import {withTranslation} from "../../i18n";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import css from './_layout.module.scss';
import {PublicationCategory} from "@/models/Publication";
import PublicationsService from "@/services/domain/PublicationsService";

const namespacesRequired = ['common'];

const Layout = (props) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    let handleMenuToggle = () => {
        return setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={`App theme__${props.theme || 'classic'}`}>
            <Navbar principal={props.principal}
                    potPage={props.potPage}
                    conceptPage={props.conceptPage}
                    spendPage={props.spendPage}
                    giftPage={props.giftPage}
                    dashboard={props.dashboard}
                    searchPage={props.searchPage}
                    partnerPage={props.partnerPage}
                    honeymoonPage={props.honeymoonPage}
                    publicationPage={props.publicationPage}
                    triggerSidebar={() => handleMenuToggle()}
                    isMenuOpen={isMenuOpen}
                    homePage={props.homePage}
                    user={props.user}
                    avdn={props.avdn}
                    honeymoonPageLink={PublicationsService.getPublicationLink({category: PublicationCategory.HONEYMOON, reference: process.env.REACT_HONEYMOON_PUBLICATION_REFERENCE, title:  process.env.REACT_HONEYMOON_PUBLICATION_TITLE}, props.t)}
            />

            {!props.avdn &&
            <MobileNavbar principal={props.principal}
                          isMenuOpen={isMenuOpen}
                          triggerSidebar={() => handleMenuToggle()}
                          honeymoonPageLink={PublicationsService.getPublicationLink({category: PublicationCategory.HONEYMOON, reference: process.env.REACT_HONEYMOON_PUBLICATION_REFERENCE, title:  process.env.REACT_HONEYMOON_PUBLICATION_TITLE}, props.t)}
            />}

            <main className={`${css.main__content} ${props.pageClass || ''} `}>
                {props.children}
            </main>
          <Footer/>
        </div>);
};

export default withTranslation(namespacesRequired)(Layout);
