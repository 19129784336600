import React, { useState } from "react";
import CollapseHeader from "./CollapseHeader";
import classNames from "classnames";
import css from "./_Collapse.module.scss";
import CollapseBody from "./CollapseBody";

const Collapse = ({ children, customClass, header, open }) => {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <div className={classNames(css.Collapse, customClass)}>
      <CollapseHeader isOpen={isOpen} setIsOpen={setIsOpen}>
        {header}
      </CollapseHeader>

      {isOpen && <CollapseBody>{children}</CollapseBody>}
    </div>
  );
};

Collapse.defaultProps = {
  open: false
}

export default Collapse;
