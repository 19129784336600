/**
 * AdsService
 * @module Services/utils/AdsService
 * @description Offers utility functions to handle strings
 */
const self = {};

const LOCAL_STORAGE_GA_TAG = "ads_active";

/**
 * TODO
 * @author Ahmed TOUAITI
 * @alias getInitials
 * @memberof module:Services/utils/AdsService
 */
self.enableAdsCookie = () => {
  localStorage.setItem(LOCAL_STORAGE_GA_TAG, "enable");
};

self.isAdsActive = () => {
  const res = localStorage.getItem(LOCAL_STORAGE_GA_TAG) === "enable";
  return res;
};

self.isGTMActive = () => {
  return self.isAdsActive();
};

self.disableAdsCookie = () => {
  localStorage.setItem(LOCAL_STORAGE_GA_TAG, "disable");
};

export default self;
