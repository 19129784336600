export const ProductGender = {
    ALL: 3,
    FEMALE: 1,
    MALE: 0,
};


export const ProductCategory = {
    MEUBLE: 1,
    DECO: 2,
    LINGE_MAISON: 3,
    TABLE: 4,
    CULINAIRE: 5,
    VOYAGE: 6,
    ELECTROMENAGER: 7,
    MULTIMEDIA: 8,
    LOISIRS: 9,
    MODE: 10

};

export const ProductSubCategory = {
    MEUBLE : {
        SALON: 11,
        SALLE_A_MANGER: 12,
        CUISINE: 13,
        MEUBLE_DE_RANGEMENT: 14,
        BUREAU: 15,
        CHAMBRE: 16,
        SALLE_DE_BAIN: 17,
        MEUBLE_DE_JARDIN: 18,
        CHAMBRE_ENFANT_BEBE: 19,
    },
    DECO : {
        LUMINAIRE: 21,
        OBJET_DE_DECORATION: 22,
        TAPIS: 23,
        BOUGIETTES_ET_SENTEURS: 24,
        DECORATION_MURALE: 25,
        MIROIR: 26,
        COUSSIN: 27,
        VASES: 56,
        CADRES_PHOTO: 57,
        ANIMAUX: 58,
    },
    LINGE_MAISON :{
        LINGE_DE_LIT: 31,
        LINGE_DE_TOILETTE: 32,
        PEIGNOIRS: 33,
        LINGE_DE_TABLE: 34,
        LINGE_DE_CUISINE: 35,
        COUETTES_ET_OREILLERS: 36,
        RIDEAUX_ET_STORES: 37,
    },
    TABLE: {
        VAISSELLE: 41,
        VERRES_ET_CARAFES: 42,
        OENOLOGIE: 43,
        COUVERTS: 59,
        SALADIERS_PLATS: 60,
        PLATEAUX: 61,
        TASSES_THEIERE: 62,
    },
    CULINAIRE: {
        USTENSILES: 51,
        CASSEROLES_POELES_ET_WOKS: 52,
        PLATS_EN_FONTE: 53,
        FAITOUTS_ET_MARMITES: 54,
        MOULES_PLATS: 63,
    },
    ELECTROMENAGER: {
        GROS_ELECTROMENAGER: 71,
        PETIT_ELECTROMENAGER: 72,
    },
    MULTIMEDIA: {
        SMARTPHONE_ET_GPS: 81,
        TV_IMAGE_ET_SON: 82,
        JEUX_VIDEO: 83,
    },
    MODE: {
        FEMME: 100,
        HOMME: 101
    }
}

export const ProductAgeRange = {
    ALL: 0,
    ZER0_FIVE: 1,
    FIVE_FIFTEEN: 2,
    FIFTEEN_TWENTYFIVE: 3,
    TWENTYFIVE_THIRTYFIVE: 4,
    THIRTYFIVE_FOURTYFIVE: 5,
    FOURTYFIVE_SIXTY: 6,
    SIXTY_PLUS: 7
};

export const ProductType = {
    GIFT: 0,
    MARRIAGE: 1,
    GIFT_MARRIAGE: 2
};

export const ProductSortCriteria = {
    PLACEMENT: 0,
    ASC_PRICE: 1,
    DESC_PRICE: 2,
    NEW: 3
};

export const ProductDefaultMaxPrice = 5000;
export const ProductDefaultMinPrice = 0;

export const GLStoreReference = 'GL';
export const BHVStoreReference = 'BHV';

