/**
 * HttpService
 * @module Services/http/HttpService
 * @name HttpService
 * @description Returns a HttpService object after configuring an axios instance
 */

import axios from "axios";
import RequestInterceptor from "./RequestInterceptor";
import ResponseHandler from "./ResponseHandler";
const axiosRetry = require("axios-retry");

const create = (ctx) => {
  const client = axios.create({
    baseURL:
      typeof window == "undefined"
        ? process.env.REACT_INTERNAL_BACKEND_BASE_URL
        : process.env.REACT_BACKEND_BASE_URL,
    timeout: 60000,
  });

  axiosRetry(client, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
      console.log(`retry attempt: ${retryCount}`);
      return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => {
      // if retry condition is not specified, by default idempotent requests are retried
      return !error.response || error.response.status === 502 || error.response.status === 504;
    },
  });

  client.interceptors.request.use(RequestInterceptor(ctx));
  client.interceptors.response.use(
    ResponseHandler.handleSuccess(ctx),
    ResponseHandler.handleError
  );

  return client;
};

export default create;
