import {addItemToCart} from './cart.utils';

const CartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_LOCAL_STORAGE_TO_STATE':
            return {
                ...state,
                cartItems: action.payload,
            };
        case 'ADD_CART_ITEM':
            return {
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload, action.participationAmount),
            };
        case 'REMOVE_CART_ITEM':
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.reference !== action.payload),
            };

        default:
            return state;
    }
};

export default CartReducer;