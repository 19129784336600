/**
 * LinksProvider
 * @module Services/http/LinksProvider
 * @name global.LinksProvider
 * @description Provides the application uris
 */

const self = {};

/**
 * Get a link after injecting its parameters
 * @name LinksProvider.get
 * @author Sameh Bellez
 * @alias get
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted endpoint url
 */
self.get = (endpoint, params) => {
  if (!params) {
    return endpoint;
  }

  let result = endpoint;
  for (let field in params) {
    result = result.replace(`:${field}`, params[field]);
  }

  return result;
};

/**
 * Gets a link with query params after injecting its parameters
 * @author Seif Khiari
 * @alias getRouteWithQuery
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted endpoint url
 */
self.getRouteWithQuery = (route, params) => {
  if (!params) {
    return route;
  }
  let result = route;
  for (let field in params) {
    if (params[field] != null) {
      result = result.replace(`:${field}`, encodeURIComponent(params[field]));
    } else {
      result = result.replace(`${field}=:${field}`, '');
      result = result.replace(`:${field}`, '');
    }
  }
  return result;
};

/**
 * Get a full link after injecting its parameters
 * @name LinksProvider.getFull
 * @author Sameh Bellez
 * @alias getFull
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted full endpoint url
 */
self.getFull = (endpoint, params) => {
  return process.env.REACT_BACKEND_BASE_URL + self.get(endpoint, params);
};

/**
 * Get Frontend full link after injecting its parameters
 * @name LinksProvider.getFullRoute
 * @author Seif Khiari
 * @alias getFullRoute
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted full endpoint url
 */
self.getFullRoute = (endpoint, params) => {
  return process.env.REACT_FRONTEND_BASE_URL + self.get(endpoint, params);
};

/**
 * Get Cagnotte Frontend full link after injecting its parameters
 * @name LinksProvider.getFullCagnotteRoute
 * @author Seif Khiari
 * @alias getFullCagnotteRoute
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted full endpoint url
 */
self.getFullCagnotteRoute = (endpoint, params) => {
  return process.env.REACT_CAGNOTTE_FRONTEND_BASE_URL + self.get(endpoint, params);
};

/**
 * Get a full link after injecting its parameters
 * @name LinksProvider.API
 */
self.API = {
  AUTH: {
    FACEBOOK: '/auth/facebook',
    GOOGLE: '/auth/google',
    SIGN_IN: '/auth/signin',
    SIGN_UP: '/auth/signup',
    SIGN_UP_M1L: '/auth/m1l/signup',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/forgot-password/:reference',
    UPDATE_USER_PASSWORD: '/auth/update-password',
    ACTIVATE_ACCOUNT: '/users/activate-account/:reference',
    RESEND_ACTIVATE_ACCOUNT: "/users/resend-activation-link",
    CHECK_M1L_MISSING_INFO: "/auth/m1l-signin-missing-info"
  },
  LISTS: {
    LISTS: '/lists',
    SEARCH: '/lists?&searchKey=:searchKey&limit=:limit&offset=:offset',
    LIST: 'lists/:reference',
    GET_LIST: '/lists/:reference',
    LIST_PRODUCTS: '/lists/products',
    CUSTOM_LIST_PRODUCT: '/lists/products/:reference',
    GET_LIST_PRODUCTS_BY_REF: '/lists/:reference/products',
    UPDATE_LIST_PRODUCT :'/lists/:reference/products/:productReference',
    ADD_LIST_PRODUCT: '/lists/products/link',
    GET_LIST_PRODUCT_PARTICIPANTS: '/lists/:reference/products/:productReference/participations',
    REMOVE_LIST_PRODUCT: '/lists/products/unlink',
    VALIDATE_URL: 'lists/validate-url?reference=:customUrl',
    PARTICIPANTS: '/pots/:reference/participants',
    TMP_IMAGE: '/pots/tmp-picture',
    DESC_IMAGE: '/pots-description-images',
    CONTACT_ORGANIZER: '/pots/:reference/contact-organizer',
    GREETING_CARD: {
      GREETING_CARD: '/pots/:reference/greeting-card',
      GREETING_CARD_MESSAGES: '/pots/:reference/greeting-card/messages',
      GREETING_CARD_MESSAGE: '/pots/:reference/greeting-card/messages/:messageReference',
      GREETING_CARD_MESSAGE_PDF: '/pots/:potReference/greeting-card/pdf'
    },
    OFFER_REQUEST: '/lists/:reference/offer-request',
    GIVEAWAY_CONFIRM: '/lists/:potReference/offer-confirmation/:reference',
    POT_BENEFICIARY: '/lists/:reference/offered',
    UPDATE_STATUS: '/pots/:reference/update-status',
    CANCEL_GIVEAWAY_REQUEST: '/lists/:reference/cancel-offer-request',
    THANK_PARTICIPANTS: '/pots/:reference/thank-participants',
    CASH_OUT: '/pots/:reference/cash-outs',
    CASH_OUT_CONFIRM: '/pots/:reference/cash-outs/:cashOutReference',
    RESEND_SPOUSE_CONFIRMATION: '/lists/:reference/resend-spouse-confirmation',
    GET_ACCOUNT_VALIDATION_PROGRESS: '/lists/:reference/list-validation-progress',
    ADD_INSPIRATION_LIST_PRODUCTS: '/lists/inspiration-lists/:reference/link',
    VDM_ESTIMATE: '/lists/:reference/vdm-estimate',
    AVDN_VALIDATE_CGU: '/lists/:reference/vdm/avdn-validate',
    PARTICIPANTS_RECUP: '/lists/:reference/participations-recup',
    REORGANIZE_LIST_PRODUCTS: '/lists/:reference/products/reorganize'
  },
  PRODUCTS: {
    PRODUCT: '/products/:reference',
    SEARCH: '/products?categories=:categories&sub_categories=:sub_categories&age_ranges=:age_ranges&type=:type&gender=:gender&min_price=:min_price&max_price=:max_price&sort_criteria=:sort_criteria&is_favorite=:is_favorite&inspiration_list=:inspiration_list&limit=:limit&offset=:offset&brands=:brands',
    BRANDS: '/products/brands?categories=:categories&sub_categories=:sub_categories&age_ranges=:age_ranges&type=:type&gender=:gender&min_price=:min_price&max_price=:max_price&sort_criteria=:sort_criteria&is_favorite=:is_favorite&inspiration_list=:inspiration_list&limit=:limit&offset=:offset',
    TMP_IMAGE: '/products/tmp-photo',
  }, PROMO_CODES: {
    PROMO_CODE: '/promo-codes/:reference',
    SPONSORSHIP_DETAILS: '/promo-codes/sponsorship-details',
  }, PARTICIPATIONS: {
    PARTICIPATIONS: '/lists/:reference/participations',
    PARTICIPATION: '/pots/:id/participations/:orderId',
    UPDATE: '/pots/:id/participations/:orderId',
    RECEIPT: '/participations/:orderId/receipt/:tabName'
  }, USER_ACTIONS: {
    USER_ACTION: '/user-actions/:reference',
    CHECK_STRONG_AUTH: '/user-action/strong-auth/:reference'
  }, USERS: {
    USERS: '/users',
    USER_PROFILE: '/users/profile',
    TMP_AVATAR: '/users/tmp-avatar',
    NOTIFICATIONS: '/users/notifications',
    USER_ACCOUNT_STATUS: '/users/dashboard-info',
    USER_CREATE_RIB: '/users/add-rib',
    USER_GET_RIB: '/users/ribs',
    USER_SPONSOR_PROMO_CODE: '/users/sponsor-promo-code',
    USER_DELETE_RIB: '/users/ribs/:reference',
    USER_OPTINS: '/users/optins',
    UPDATE_EMAIL: '/users/update-email',
    CONFIRM_EMAIL_UPDATE: '/users/update-email-confirmation/:reference',
    USER_POT: '/users/pots',
    USER_SPENDING: '/users/expenditures',
    USER_SPENDING_M1L: '/users/expenditures-m1l',
    USER_SPOUSE: '/users/spouse',
    SPOUSE_USER_ACTION: '/users/update-spouse-user-action',
    USER_CONFIRM_SPOUSE: '/users/confirm-spouse/:reference',
    GET_ACTIVE_LIST: '/users/active-list',
    GET_ACCOUNT_VALIDATION_PROGRESS: '/users/m1l-account-validation-progress',
    USER_UPDATE_PHONE: 'users/phone',
    USER_UPDATE_SPOUSE_PHONE: 'users/confirm-spouse/:reference/phone',
    USER_UPDATE_ADDRESS: 'users/address',
    USER_GET_SELF_CERTIF: '/users/self-certification',
    USER_SEND_PROFILE_DATA: '/users/send-m1l-profile-data',
    USER_SEND_DECLARATIVE_DATA: '/users/validate-list-activation-last-step',
    USER_SEND_DECLARATIVE_CGU: '/users/validate-list-activation-last-step-cgu',
    USER_SEND_DATA_FOR_RETENTION: '/users/retain-list-activation-banking-and-legal-information',
    USER_GET_PROFILE_DATA: '/users/m1l-profile',
    USER_GET_EKYC_DATA: '/users/ekyc-info',
    USER_ACTIVATE_LIST_LAST_STEP_INFO: '/users/list-activation-last-step-info',
    USER_UPDATE_M1L_KYC_STEP: '/users/m1l-kyc-step',
    GET_STATEMENT: '/users/statement/:year/:month',
    USER_CREDIT_CARD: '/users/credit-card',
    USER_CHECK_EXPENDITURES: '/users/has-expenditures-m1l',
    USER_INVITED_WEDDING_LISTS: '/users/invited-wedding-lists',
    USER_STRONG_AUTH_DATA: '/users/strong-auth-data',
    USER_KYC_PROCESS_CHOICE: '/users/kyc-process-choice'
  }, USER: {
    UPDATE_USER: '', USER_EMAIL_UPDATE: '', USER_ADD_RIB: '/users/add-rib'
  }, COMMERCIAL_BANNER: {
    DETAILS: '/commercial-banners/:type'
  }, PAGES_DATA: {
    PAGES_DATA: '/pages-data'
  }, GIFT_CARDS: {
    GENERATE: '/pots/:reference/gift-cards',
    GET_ORDER: '/pots/:potReference/gift-cards/:orderReference',
    RESEND_GIFT_CARD: '/pots/:potReference/gift-cards/:orderReference/:giftCardReference',
  },
  SIMULATIONS: {
    E_KYC_VERIFICATION: '/simulations/xpollens/validate-ekyc',
    E_FATCA_EAI_VERIFICATION: '/simulations/xpollens/validate-fatca-eai',
    STRONG_AUTH: '/simulations/auth/strong'
  },
  INSPIRATIONS_LISTS: {
    GET_ALL: '/inspiration-lists?type=:type'
  },
  PUBLICATIONS: {
    PUBLICATION: '/publications/:reference',
    SEARCH: '/publications?category=:category&searchKey=:searchKey&template=:template&limit=:limit&offset=:offset&ignoreCategoryHeadline=:ignoreCategoryHeadline',
    TOP: '/publications/top?limit=:limit',
    GET_CATEGORY_HEADLINE: '/publications/category-headline?category=:category'
  },
  CREDIT_CARDS: {
    GET_PIN: '/credit-cards/:reference/pin',
    TOGGLE_BLOCK: '/credit-cards/:reference/toggle-block',
    ACTIVATE_CARD: '/credit-cards/:reference/activate'
  },
  COMMERCIAL_PARTNERS: {
    SEARCH: '/commercial-partners?category=:category&region=:region&limit=:limit&offset=:offset',
  }, FILES: {
    FILES: '/files',
    FILE: '/files/:file_name'
  },
  DOWNLOAD: '/download/:fileName?displayName=:displayName'
};

/**
 * Get a full link after injecting its parameters
 * @name LinksProvider.ROUTES
 */
self.ROUTES = {
  HOME: '/',
  FAQ: '/faq',
  HOW_IT_WORKS: '/comment-ca-marche',
  HOW_IT_WORKS_SPEND: '/comment-ca-marche?depenser',
  SPENDING_INFORMATION: "/depenser",
  PARTNERS: "/carnet-adresses",
  CGU: "/conditions-generales-utilisation",
  CGU_PAYMENT: "/conditions-generales-utilisation#cgu-compte-paiement",
  CGU_CARD: "/conditions-generales-utilisation#cgu-cartes-paiement",
  // COOKIES: "/utilisation-des-cookies",
  AUTH: {
    SIGN_IN: '/connexion', SIGN_UP: '/inscription'
  },
  ACCOUNT: {
    DASHBOARD: '/tableau-de-bord',
    DASHBOARD_CREDIT_CARD: '/tableau-de-bord#ma-carte-bancaire',
    POT_BOARD: '/mes-cagnottes',
    LIST_BOARD: '/mes-listes',
    SPENDING_BOARD: '/mes-depenses',
    DOCUMENTS: '/mes-documents',
    ACTIVATE_LIST: '/activer-ma-liste'
  },
  LIST: {
    SEARCH: '/rechercher-une-liste?&searchKey=:searchKey&limit=:limit&offset=:offset',
    SEARCH_NO_PARAMS: '/rechercher-une-liste',
    CREATE: '/creer-une-liste',
    LIST: '/liste/:reference',
    LIST_BENEFICIARY: '/liste/:reference/beneficiaire/:email',
    PARTICIPATE: '/liste/:reference/participer',
    MANAGE: '/liste/:reference/modification',
    GIVEAWAY: '/liste/:reference/' + 'offrir',
    WITHDRAWAL: '/liste/:reference/demander-un-virement',
    WITHDRAWAL_CONFIRM: '/liste/:reference/demander-un-virement/:cashOutReference',
    USE_MONEY: '/liste/:reference/utiliser-la-liste',
    CREATE_GIFT_CARD: '/liste/:reference/depenser/carte-cadeau',
    CREATE_GIFT_CARD_CONFIRMATION: '/liste/:reference/depenser/carte-cadeau/:orderReference/confirmation',
    GREETING_CARD: '/liste/:reference/carte-de-voeux',
    GREETING_CARD_MESSAGE_CREATE: '/liste/:reference/greeting-card-messages',
    GREETING_CARD_MESSAGE_UPDATE: '/liste/:potReference/greeting-card-messages/:greetingCardMessageReference',
    GREETING_CARD_MESSAGE_DELETE: '/liste/:potReference/greeting-card-messages/:greetingCardMessageReference',
    USER_CONFIRM_SPOUSE: '/bienvenue/:reference',
    WHERE_TO_SPEND: '/liste/:reference/ou-depenser'

  },
  POT: {
    CREATE: '/creer-une-cagnotte',
    POT: '/cagnotte/:reference',
    POT_BENEFICIARY: '/cagnotte/:reference/beneficiaire/:email',
    PARTICIPATE: '/cagnotte/:reference/participer',
    MANAGE: '/cagnotte/:reference/modification',
    GIVEAWAY: '/cagnotte/:reference/' + 'offrir',
    WITHDRAWAL: '/cagnotte/:reference/demander-un-virement',
    WITHDRAWAL_CONFIRM: '/cagnotte/:reference/demander-un-virement/:cashOutReference',
    USE_MONEY: '/cagnotte/:reference/utiliser-la-cagnotte',
    CREATE_GIFT_CARD: '/cagnotte/:reference/depenser/carte-cadeau',
    CREATE_GIFT_CARD_CONFIRMATION: '/cagnotte/:reference/depenser/carte-cadeau/:orderReference/confirmation',
    GREETING_CARD: '/cagnotte/:reference/carte-de-voeux',
    GREETING_CARD_MESSAGE_CREATE: '/pots/:reference/greeting-card-messages',
    GREETING_CARD_MESSAGE_UPDATE: '/pots/:potReference/greeting-card-messages/:greetingCardMessageReference',
    GREETING_CARD_MESSAGE_DELETE: '/pots/:potReference/greeting-card-messages/:greetingCardMessageReference'
  },
  PRODUCT: {
    PRODUCT: '/gift/:reference', // IS IT USED ? TO DELETE ?
    SEARCH: '/gifts?&limit=:limit&offset=:offset&categories=:categories&age=:age&gender=:gender&min_price=:min_price&max_price=:max_price&sort=:sort&favorite=:favorite&selected_pool=:selected_pool&inspiration_list=:inspiration_list',
    SEARCH_MAX_AMOUNT: '/gifts?max_price=:max_price',
    SEARCH_NO_PARAMS: '/gifts',
    CREATE_GIFT_CARD: '/gift/:reference/carte-cadeau/:potReference',
    CREATE_GIFT_CARD_CONFIRMATION: '/gift/:reference/carte-cadeau/:potReference/confirmation/:orderReference'
  },
  OTHER: {
    M1L: 'https://www.1001listes.fr',
    CAGNOTTE: 'https://cagnotte.galerieslafayette.com'
  },
  CAGNOTTE: {
    HOME_PAGE: '/',
    POT_PAGE: '/cagnotte/:reference',
    POT_USE_MONEY: '/cagnotte/:reference/utiliser-la-cagnotte',
    POT_PARTICIPATE: '/cagnotte/:reference/participer',
    POT_CREATE: '/creer-une-cagnotte',
    POT_GREETING_CARD: '/cagnotte/:reference/carte-de-voeux',
  },
  PUBLICATION: {
    ONE: '/actualites/:category/:reference',
    ALL: '/actualites?category=:category&searchKey=:searchKey&template=:template&limit=:limit&offset=:offset',
    ALL_NO_PARAMS: '/actualites',
  },
  AVDN: {
    PAGE: '/voyage-de-noces'
  }
};

export default self;
