import React from "react";
import {ErrorMessage} from "formik";
import {withTranslation} from "../../../i18n";
import css from './InputError.module.scss';

const namespacesRequired = ['common'];

const InputError = ({t, name, customClass}) => {

    const render = (error) => {

        return (
            <div className={`${css.input__errors} ${customClass ? customClass : ''}`}>
                <p>{t(`errors.${error}`)}</p>
            </div>
        );
    };

    return (
        <ErrorMessage name={name}
                      component="div"
                      render={render}
        />);
};

export default withTranslation(namespacesRequired)(InputError);
