export const PublicationTemplate = {
  PRIMARY: 0,
  SECONDARY: 1
};

export const PublicationCategory = {
  OTHER: 0,
  NEWS: 1,
  FAVORITES: 2,
  HONEYMOON: 3,
  TABLEWARE: 4,
  BEAUTY: 5,
  COSTUMES: 6,
  DECORATION: 7,
  JEWELERY: 8,
  HOUSE_LINENS: 9,
  ACCESSORIES_FASHION: 10,
  //FASHION: 11, //deprecated
  ORGANIZATION: 12
};

export const PublicationStatus = {
  INACTIVE: 0,
  ACTIVE: 1
};