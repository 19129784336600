import React, { useEffect, useState } from 'react';
import { withTranslation } from '../../../i18n';
import AnalyticsService from "../../../services/utils/AnalyticsService";
import AdsService from "../../../services/utils/AdsService";
import GTMService from "../../../services/utils/GTMService";
import CookiesService from "../../../services/utils/CookiesService";
import Button from "../../button/simpleButton/Button";
import ToggleRadio from "../../form/toggle-radio/ToggleRadio";
import Modal from "../../modal/Modal";
import css from './ManageCookieModal.module.scss';

const namespacesRequired = ['cookie', 'common'];

const ManageCoookiesModal = ({ t, visible, onHide, onSubmit }) => {

  const [isAnalyticCookiesActive, setIsAnalyticCookiesActive] = useState(null);
  const [isMarketingCookiesActive, setIsMarketingCookiesActive] = useState(null);

  useEffect(() => {
    setIsAnalyticCookiesActive(AnalyticsService.isAnalyticsActive());
    setIsMarketingCookiesActive(CookiesService.isMarketingCookieActive());
  }, []);


  const toggleAnalyticsCookie = () => {
    setIsAnalyticCookiesActive(!isAnalyticCookiesActive);
  };

  const toggleMarketingCookie = () => {
    setIsMarketingCookiesActive(!isMarketingCookiesActive);
  };

  const submit = () => {
    if (isAnalyticCookiesActive) {
      AnalyticsService.enableAnalyticsCookie();
      AdsService.enableAdsCookie();
    } else {
      AnalyticsService.disableAnalyticsCookie();
      AdsService.disableAdsCookie();
    }
    if (isMarketingCookiesActive) {
      CookiesService.enableMarketingCookie()
    } else {
      CookiesService.disableMarketingCookie()
    }
    GTMService.sendEvent("consent_update")
    onSubmit && onSubmit();
  };

  return (
    <Modal customClass={css.cookie_modal} visible={visible} onHide={onHide}>

      <div className={css.content}>
        <div className={css.cookie_wrapper}>
          <h2 dangerouslySetInnerHTML={{ __html: t('cookie:modal.desc') }} />
        </div>
        <div className={css.cookie_wrapper}>

          {isAnalyticCookiesActive != null && (
            <ToggleRadio name={'ga-radio'}
              checked={isAnalyticCookiesActive}
              action={() => toggleAnalyticsCookie()}
              trueContent={t('cookie:yes')}
              falseContent={t('cookie:no')}
              label={t('cookie:analytics')} />
          )}
          <h2 dangerouslySetInnerHTML={{ __html: t('cookie:modal.ga') }} />
        </div>
        <div className={css.cookie_wrapper}>
          {isMarketingCookiesActive != null && (
            <ToggleRadio name={'marketing-radio'}
              checked={isMarketingCookiesActive}
              action={() => toggleMarketingCookie()}
              trueContent={t('cookie:yes')}
              falseContent={t('cookie:no')}
              label={t('cookie:marketing')} />
          )}
          <h2 dangerouslySetInnerHTML={{ __html: t('cookie:modal.marketing') }} />
        </div>
        <br/>
        <br/>
        <p dangerouslySetInnerHTML={{ __html: t('cookie:modal.more', { link: "/static/pdf/pvp_gl_mariage_nov_2024.pdf" }) }} />

        <Button className={css.btn__primary} onClick={() => {
          submit();
        }}>
          {t('common:validate')}
        </Button>
      </div>
    </Modal>
  );
};

export default withTranslation(namespacesRequired)(ManageCoookiesModal);
