import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from './PageWrapper.module.scss';


const propTypes = {
  children: PropTypes.node,
  customClass: PropTypes.string,
};

class PageWrapper extends Component {
  componentDidMount() {
    if (window.$ && window.$.AdminLTE) {
      window.$.AdminLTE.layout.fix();
    }
  }

  render() {
    return (
      <div className={`${css.wrapper} ${this.props.customClass}`}>
        {this.props.children}
      </div>
    );
  }
}

PageWrapper.propTypes = propTypes;

export default PageWrapper;
