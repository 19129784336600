import React, {useReducer, useEffect} from 'react';
import UserContext from './UserContext';
import userReducer from './UserReducer';
import UserService from "../../services/domain/UserService";
import AuthService from "../../services/domain/AuthService";

const UserProvider = props => {

    const initialState = {
        activeList: null,
        isFetching: true,
        errorMessage: null,
        principal: null
    };


    const [state, dispatch] = useReducer(userReducer, initialState);





    // Get user active List
    const getActiveList = async () => {
        if (!AuthService.isAuthenticated()) {
            return dispatch({type: 'GET_ACTIVE_LIST_SUCCESS', payload: null});
        }
        if (state.activeList) {
            return;
        }
        try {
            dispatch({type: 'GET_ACTIVE_LIST_START'});
            const data = await UserService.getActiveList();
            dispatch({type: 'GET_ACTIVE_LIST_SUCCESS', payload: data});
        } catch (err) {
            dispatch({type: 'GET_ACTIVE_LIST_FAILURE', payload: err.message});
        }
    };

    const getPrincipal = () => {
        const principal = AuthService.getPrincipal();
        dispatch({type: 'UPDATE_PRINCIPAL', payload: principal});
    }

    useEffect(() => {
        getActiveList()
        getPrincipal()
    }, []);

    return (
        <UserContext.Provider
            value={{
                activeList: state.activeList,
                getActiveList: getActiveList,
                isFetching: state.isFetching,
                principal: state.principal,
                getPrincipal: getPrincipal
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;