import React, { useContext, useEffect, useState } from 'react';
import Navlink from './navlink/Navlink.js';
import Navbrand from './navbrand/Navbrand';
import LoginButton from './login-button/LoginButton';
import ProfileButton from './profile-button/ProfileButton';
import LinksProvider from '../../services/http/LinksProvider';
import { withTranslation } from '../../i18n';
import NavbarRadio from '../navbar-radio/NavbarRadio';
import UserService from '../../services/domain/UserService';
import css from './_navbar.module.scss';
import UserContext from "../../context/user/UserContext";
import productContext from '@/context/product/ProductContext.js';

const namespacesRequired = ['common'];

const Navbar = ({ principal, potPage, dashboard, t, conceptPage, spendPage, giftPage, homePage, publicationPage, partnerPage, triggerSidebar, isMenuOpen, searchPage, honeymoonPageLink, avdn, honeymoonPage }) => {
    const userContext = useContext(UserContext);
    const { showCategoriesFilter, showInspirationListFilter, showMarqueFilter } =
        useContext(productContext);

    const [scrollZero, setScrollZero] = useState(true);
    const [componentLoaded, setComponentLoaded] = useState(false);
    const [windowWidth, setWindowWidth] = useState(null);
    const [user, setUser] = useState(principal);
    const { getActiveList, activeList, isFetching } = userContext;
    const [hideNavLink, setHideNavLink] = useState(false);

    let handlePageScroll = () => {
        if (window.scrollY === 0) {
            setScrollZero(true);
        }

        if (window.scrollY !== 0) {
            setScrollZero(false);
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', handlePageScroll);
        handlePageScroll();
        setWindowWidth(window.innerWidth);
        setComponentLoaded(true);

    }, []);

    useEffect(() => {
        return () => {
            UserService.getAccountProfile().then(setUser);
        };
    }, [setUser]);

    const onHideNavLink = (value) => {
        setHideNavLink(value)
    }


    return (<React.Fragment>
        <nav
            className={`${css.nav}
                  ${homePage && scrollZero ? '' : css.scrolled}
                  ${potPage ? css.nav__pot : ''}
                  ${dashboard ? css.nav__dashboard : ''}
                  ${avdn && css.nav__avdn}`}
            role="navigation"
            style={{ zIndex: showCategoriesFilter || showInspirationListFilter || showMarqueFilter ? -1 : undefined }}
        >
            <div className={css.nav__logo}>
                {!avdn &&
                    <img src={isMenuOpen ? "/static/images/pictos/burger-close-1.svg" : "/static/images/pictos/icon-burger.svg"}
                        onClick={triggerSidebar}
                        className={css.nav__bars}
                    />

                }

                <Navbrand imgSrc={"/static/images/logos/list-logo-bl.svg"}
                    imgDesc={t('common:navbar.logo-alt')}
                    styleOverwrite={`${css.overwrite} ${!avdn && css.overwrite__center}`}
                />
            </div>
            {!avdn &&
                <section
                    className={`${css.nav__menu}`}
                >
                    <section className={css.nav__group}>
                        <div className={hideNavLink ? css.navlink__container__hide : css.navlink__container}>
                            <Navlink url={LinksProvider.get(LinksProvider.ROUTES.HOW_IT_WORKS)}
                                name={t('common:navbar.ccm')}
                                customClass={homePage && scrollZero ? 'white' : ''}
                                active={conceptPage}
                            />

                            <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PRODUCT.SEARCH_NO_PARAMS)}
                                name={t('common:navbar.gifts')}
                                customClass={homePage && scrollZero ? 'white' : ''}
                                active={giftPage}
                            />

                            <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PUBLICATION.ALL_NO_PARAMS)}
                                name={t('common:navbar.threads')}
                                customClass={homePage && scrollZero ? 'white' : ''}
                                active={publicationPage}
                            />
                            <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PARTNERS)}
                                name={t('common:navbar.partners')}
                                customClass={homePage && scrollZero ? 'white' : ''}
                                active={partnerPage}
                            />
                            <Navlink url={LinksProvider.ROUTES.AVDN.PAGE}
                                name={t('common:navbar.honeymoon')}
                                customClass={homePage && scrollZero ? 'white' : ''}
                                active={honeymoonPage}
                            />
                        </div>
                    </section>

                    <a
                        href={!activeList
                            ? LinksProvider.get(LinksProvider.ROUTES.LIST.CREATE)
                            : LinksProvider.get(LinksProvider.ROUTES.LIST.LIST, { reference: activeList.reference })}
                        className={css.btn__primary}
                    >
                        {isFetching ? '' : (!activeList
                            ? t('common:navbar.create')
                            : t('common:consult-list'))}
                    </a>


                </section>
            }

            <section className={css.nav__group}>
                {!principal && (<LoginButton />)}
                {principal && (<React.Fragment>
                    <ProfileButton principal={principal} />
                    {user && user.n_user_notifications && (user.n_user_notifications > 0) && (
                        <NavbarRadio text={user.n_user_notifications} />)}
                </React.Fragment>)}
            </section>
        </nav>
    </React.Fragment>);

};

export default withTranslation(namespacesRequired)(Navbar);
