import AuthService from '../domain/AuthService';
import ErrorHandler from '../error/ErrorHandler';
import LinksProvider from '../http/LinksProvider';
import SavedActionsService from '../utils/SavedActionsService';
import {ToastProvider, useToasts} from "react-toast-notifications";
import React, {useEffect, useState} from "react";
import {withTranslation} from "../../i18n";
import WithGA from "./withGa";
import cookies from 'next-cookies';
import CookiesBanner from "../../shared/cookies/cookies-banner/CookiesBanner";
import Router from 'next/router'

const redirect = (ctx, url) => {
  typeof window !== 'undefined'
    ? Router.push(url)
    : ctx.res.writeHead(302, { Location: url }).end()
};

export const withPageWrapper = ({authenticated, namespacesRequired, hasAccess, allCookies} = {}) => {
  return WrappedComponent => {
    const Wrapper = props => {
      const {addToast} = useToasts();
      const [isAnalyticCookiesActive] = useState(null);

      useEffect(() => {
        ErrorHandler.displayCookieErrorMessages(props.errorMessages, addToast, props.t);
        ErrorHandler.displayCookieSuccessMessages(props.successMessages, addToast, props.t);
      }, []);

      return (
        <React.Fragment>
          <WithGA
            isAnalyticCookiesActive={isAnalyticCookiesActive}
            content={<WrappedComponent {...props} />}/>


          <CookiesBanner/>

        </React.Fragment>

      );
    };

    Wrapper.getInitialProps = async ctx => {
      const principal = AuthService.getPrincipal(ctx);
      const allCookies = cookies(ctx);

      //Extract cookies messages
      const errorMessages = ErrorHandler.getCookieErrorMessages(ctx);
      const successMessages = ErrorHandler.getCookieSuccessMessages(ctx);

      //redirect if the user is not connected and the page is protected
      if (ctx && ctx.res && authenticated && !principal) {
        SavedActionsService.saveRedirectUrl(ctx, ctx.req.url);
        redirect(ctx, LinksProvider.ROUTES.AUTH.SIGN_IN);
        return;
      }

      let componentProps;
      try {
        componentProps = WrappedComponent.getInitialProps
          && (await WrappedComponent.getInitialProps(ctx));
      } catch (e) {
        if (e && e.code && e.code === 'E_UNAUTHORIZED') {
          redirect(ctx, LinksProvider.ROUTES.AUTH.SIGN_IN);
          return;
        }
      }

      if (ctx && ctx.res && hasAccess && !hasAccess(componentProps, ctx)) {
        return;
      }

      return {...componentProps, principal, errorMessages, successMessages, allCookies};
    };

    return withTranslation(namespacesRequired)(Wrapper);
  };
};
