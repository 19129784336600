import CurrencyService from "./CurrencyService";

/**
 * IAdvizeService
 * @module Services/utils/IAdvizeService
 * @description Offers utility functions to handle IAdvize chat tags
 */

const self = {};

self.IADVIZE_PAGE_TYPES = {
  HOMEPAGE: 'homepage',
  PRODUCT: 'produit',
  CART: 'panier',
  ACCOUNT: 'mon compte',
  LIST: 'liste marriage',
  SEARCH: 'recherche'
};

/**
 * Injects IAdvize conversion tag script
 * @author Seif Khiari
 * @alias injectConversionScript
 * @memberof module:Services/utils/IAdvizeService
 * @param   {object} participation the participation data
 */
self.injectConversionScript = (participation) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  console.log("participation inject", participation);
  script.text = `var idzTrans = {"cartAmount":"${CurrencyService.centsToEuro(participation.amount)}","tID":"${participation.reference}"};`;
  const firstScript = document.head.getElementsByTagName('script')[0];
  document.head.insertBefore(script, firstScript);
};

const getPersonalDataObject = (data, pageType) => {
  let personalData;
  switch (pageType) {
    case self.IADVIZE_PAGE_TYPES.HOMEPAGE:
    case self.IADVIZE_PAGE_TYPES.ACCOUNT:
    case self.IADVIZE_PAGE_TYPES.LIST:
    case self.IADVIZE_PAGE_TYPES.SEARCH:
      personalData = {page_type: pageType};
      break;
    case self.IADVIZE_PAGE_TYPES.PRODUCT:
      personalData = {page_type: pageType};
      data.product && data.product.type && (personalData.product_type = data.product.type);
      data.product && data.product.price && (personalData.product_price = data.product.price);
      break;
    default:
      personalData = {page_type: self.IADVIZE_PAGE_TYPES.HOMEPAGE};
      break;
  }

  if (data.principal && personalData) {
    data.principal.first_name && (personalData.cust_firstname = data.principal.first_name);
    data.principal.last_name && (personalData.cust_name = data.principal.last_name);
    data.principal.phone && (personalData.cust_phonenumber = data.principal.phone);
    data.principal.email && (personalData.cust_email = data.principal.email);
    data.principal.id && (personalData.client_id = data.principal.id);
  }

  return `var idzCustomData = ${JSON.stringify(personalData)};`;
};

/**
 * Injects IAdvize personal data tag script
 * @author Seif Khiari
 * @alias injectPersonalDataScript
 * @memberof module:Services/utils/IAdvizeService
 * @param   {string} tag The cookie tag
 * @param   {object} data The data to be saved
 */
self.injectPersonalDataScript = (data, pageType) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.text = getPersonalDataObject(data, pageType);
  const firstScript = document.head.getElementsByTagName('script')[0];
  document.head.insertBefore(script, firstScript);
};


export default self;
