import classNames from "classnames";
import { useTranslation } from "../../../../i18n";
import homeCss from "../../home.module.scss";
import css from "./_home-faq.module.scss";
import ListTitle from "@/shared/textes/ListTitle/ListTitle";
import Collapse from "@/shared/Collapse";

const HomeFAQ = () => {
  const { t } = useTranslation();
  const items = 3;

  return (
    <section className={classNames(homeCss.section)}>
      <ListTitle
        title={t("home:home-faq.title")}
        subtitle={t("home:home-faq.subtitle")}
        customClassSubtitle={css.subtitle}
        customClassTitle={css.title}
      />

      {Array.from({ length: items }, (_, index) => index).map((index) => (
        <Collapse
          header={t(`home:home-faq.title-${index}`)}
          customClass={css.Collapsible}
        >
          <p>{t(`home:home-faq.description-${index}`)}</p>
        </Collapse>
      ))}
    </section>
  );
};

export default HomeFAQ;
