import React from "react";
import Link from 'next/link';
import LinksProvider from '../../../services/http/LinksProvider';
import css from './LoginButton.module.scss';

const LoginButton = () => {
  return (<Link href={LinksProvider.get(LinksProvider.ROUTES.AUTH.SIGN_IN)}>
    <a>
      <span>
        <button className={css.auth__btn}>
          <img src="../../../static/images/pictos/icon-user.svg"/>
          <span className={css.auth__content}>Connexion</span>
        </button>
      </span>
    </a>
  </Link>);
};

export default LoginButton;
