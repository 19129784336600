import React from "react";
import Head from "next/head";
import LinksProvider from "@/services/http/LinksProvider";
import {withTranslation} from "../../i18n";
import { useRouter } from 'next/router';

const namespacesRequired = ['home'];

const OpenGraphHead = ({title, meta_title, description, url, image, t, ...props}) => {
  const router = useRouter();
  const og_description = description ? description : t('home:meta-description');
  const og_image = image ? image : LinksProvider.getFullRoute('/static/images/logos/og-image.jpeg');
  const og_url = url ? url : router.pathname;
  const og_title = title ? title : t('home:meta-title');

  return (
    <Head>
      <title>{meta_title ? meta_title : og_title}</title>
      <meta name="description" content={og_description} key={"description"}/>
      <meta property="og:type" content="website" key={"og_type"}/>
      <meta property="og:title" content={og_title} key={"og_title"}/>
      <meta property="og:url" content={og_url} key={"og_url"}/>
      <meta property="og:description" content={og_description} key={"og_description"}/>
      <meta property="fb:app_id" content={process.env.REACT_FACEBOOK_APP_ID} key={"fb_app_id"}/>
      <meta property="og:image" content={og_image} key={"og_image"}/>
      <meta property="og:image:width" content="1200" key={"og_image_width"}/>
      <meta property="og:image:height" content="630" key={"og_image_height"}/>
      {props.children}
    </Head>
  );
};

export default withTranslation(namespacesRequired)(OpenGraphHead);
