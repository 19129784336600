import React, {useEffect, useState} from 'react';
import AnalyticsService from "./AnalyticsService";
import AdsService from "./AdsService";
import {useRouter} from 'next/router';

const WithGA = ({content}) => {
  const router = useRouter();

  useEffect(() => {
    if (AnalyticsService.isAnalyticsActive()) {
      AnalyticsService.enableAnalyticsCookie();
      AdsService.enableAdsCookie();
    }
  }, []);


  return (
    <div>
      {content}
    </div>
  );
};

export default WithGA;

