import css from "../footerNewsletter/FooterNewsletter.module.scss"

const SocialMediaLinks = () => {
    return (
        <div className={css.socialShare}>
            <a
                href={"https://www.instagram.com/milleetunelistes/"}
                target={"_blank"}
            >
                <img
                    src={"/static/images/footer/primary-ig.svg"}
                    alt={"instagram"}
                />
            </a>
            <a
                href={"https://www.pinterest.fr/milletunelistes/_saved/"}
                target={"_blank"}
            >
                <img
                    src={"/static/images/footer/primary-pinterest.svg"}
                    alt={"pinterest"}
                />
            </a>
            <a href={"https://www.facebook.com/1001listes"} target={"_blank"}>
                <img
                    src={"/static/images/footer/primary-facebook.svg"}
                    alt={"facebook"}
                />
            </a>

        </div>
    )
}


export default SocialMediaLinks