/**
 * PagesDataService
 * @module Services/domain/PagesDataService
 * @description Offers a set of methods to help performing pages data related actions
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

const self = {};

/**
 * Gets pages data
 * @author Sameh Bellez
 * @alias get
 * @memberof module:Services/domain/PagesDataService
 * @returns {promise}  A promise containing the resulting pages data
 */
self.get = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.PAGES_DATA.PAGES_DATA))
      .then(result => result.pages_data);
};

export default self;