const ProductReducer = (state, action) => {
  switch (action.type) {
    case "GET_PRODUCTS_START":
      return {
        ...state,
        isFetching: true,
      };
    case "GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        listProducts: action.payload,
        isFetching: false,
      };
    case "GET_PRODUCTS_FAILURE":
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case "ADD_PRODUCT_START":
      return {
        ...state,
        isFetching: true,
        showToastAddSuccess: false,
        showToastFailure: false,
      };
    case "UPDATE_PRODUCT_START":
      return {
        ...state,
        isFetching: true,
        showToastUpdateSuccess: false,
        showToastFailure: false,
      };

    case "UPDATE_PRODUCT_SUCCESS":
      return {
        ...state,
        listProducts: action.payload
          ? state.listProducts.map((item) =>
              item.reference !== action.payload.reference
                ? item
                : action.payload
            )
          : state.listProducts,
        isFetching: false,
        showToastUpdateSuccess: true,
        showToastFailure: false,
      };

    case "UPDATE_PRODUCT_FAILURE":
      return {
        ...state,
        isFetching: false,
        showToastUpdateSuccess: false,
        showToastFailure: true,
      };
    case "ADD_CUSTOM_PRODUCT_START":
      return {
        ...state,
        isFetching: true,
        showToastAddCustomSuccess: false,
        showToastFailure: false,
      };
    case "UPDATE_CUSTOM_PRODUCT_START":
      return {
        ...state,
        isFetching: true,
        showToastUpdateSuccess: false,
        hideModalAfterSubmit: true,
        showToastFailure: false,
      };
    case "UPDATE_CUSTOM_PRODUCT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        hideModalAfterSubmit: true,
        listProducts: action.payload
          ? state.listProducts.map((item) =>
              item.reference !== action.payload.reference
                ? item
                : action.payload
            )
          : state.listProducts,
        showToastUpdateSuccess: true,
        showUpdateToastFailure: false,
      };
    case "ADD_PRODUCT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        hideModalAfterSubmit: true,
        listProducts: [...state.listProducts, action.payload],
        showToastAddSuccess: true,
      };
    case "ADD_CUSTOM_PRODUCT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        hideModalAfterSubmit: true,
        listProducts: [...state.listProducts, action.payload],
        showToastAddCustomSuccess: true,
      };
    case "ADD_PRODUCT_FAILURE":
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        showToastOrRedirectOnAddFailure: true,
      };
    case "ADD_CUSTOM_PRODUCT_FAILURE":
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        hideModalAfterSubmit: false,
        showToastFailure: true,
      };
    case "UPDATE_CUSTOM_PRODUCT_FAILURE":
      return {
        ...state,
        isFetching: false,
        updateErrorMessage: action.payload,
        hideModalAfterSubmit: false,
        showUpdateToastFailure: true,
      };
    case "REMOVE_PRODUCT_START":
      return {
        ...state,
        isFetching: true,
        showToastRemoveSuccess: false,
        showToastFailure: false,
      };
    case "REMOVE_PRODUCT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        listProducts: state.listProducts.filter(
          (item) => item.reference !== action.payload
        ),
        showToastRemoveSuccess: true,
      };
    case "REMOVE_PRODUCT_FAILURE":
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        showToastFailure: true,
      };
    case "SET_ACTIVE_PRODUCT":
      return {
        ...state,
        activeProduct: action.payload,
      };
    case "AFTER_NOTIFICATION":
      return {
        ...state,
        showToastAddCustomSuccess: false,
        showToastFailure: false,
        showToastUpdateSuccess: false,
        showToastRemoveSuccess: false,
        showToastAddSuccess: false,
      };
    case "TOGGLE_CATEGORIES_FILTER":
      return {
        ...state,
        showCategoriesFilter: !state.showCategoriesFilter,
        showInspirationListFilter: false,
        showMarqueFilter: false,
      };
    case "TOGGLE_INSPIRATION_LIST_FILTER":
      return {
        ...state,
        showCategoriesFilter: false,
        showInspirationListFilter: !state.showInspirationListFilter,
        showMarqueFilter: false,
      };
    case "TOGGLE_MARQUE_FILTER":
      return {
        ...state,
        showCategoriesFilter: false,
        showInspirationListFilter: false,
        showMarqueFilter: !state.showMarqueFilter,
      };
    default:
      return state;
  }
};

export default ProductReducer;
