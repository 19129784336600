import React from "react";
import css from "./_callapse-header.module.scss";

const CollapseHeader = ({ children, isOpen, setIsOpen }) => {
  return (
    <div
      className="t-bold flex space-between pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      {children}

      <div className={css.CollapseHeader}>
        <img
          src={"/static/images/pictos/chevron.svg"}
          style={{
            transform: isOpen ? "rotate(180deg)" : "unset",
            height: "16px",
            width: "21px",
          }}
        />
      </div>
    </div>
  );
};

export default CollapseHeader;
