import PerksCarousel from "@/components/perks/PerksCarousel/PerksCarousel";
import css from "./HomePerks.module.scss";
import { useTranslation } from "../../../../i18n";

const HomePerks = () => {
  const { t } = useTranslation();

  const PERKS = [
    {
      image: `/static/images/advantages/advantage-0.svg`,
      title: t(`home:perks.advantage_0`),
    },
    {
      image: `/static/images/advantages/advantage-1.svg`,
      title: t(`home:perks.advantage_1`),
    },
    {
      image: `/static/images/advantages/advantage-2.svg`,
      title: t(`home:perks.advantage_2`),
    },
    {
      image: `/static/images/advantages/advantage-3.svg`,
      title: t(`home:perks.advantage_3`),
    },
    {
      image: `/static/images/advantages/advantage-4.svg`,
      title: t(`home:perks.advantage_4`),
    },
    {
      image: `/static/images/advantages/advantage-5.svg`,
      title: t(`home:perks.advantage_5`),
    },
  ];

  return (
    <div className={css.container}>
      <PerksCarousel
        perks={PERKS}
        title={t("home:perks.title")}
        subtitle={t("home:perks.subtitle")}
      />
    </div>
  );
};

export default HomePerks;
