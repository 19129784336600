import React, { useEffect, useState } from 'react';
import { withTranslation } from "../../../i18n";
import AnalyticsService from "../../../services/utils/AnalyticsService";
import AdsService from "../../../services/utils/AdsService";
import CookiesService from "../../../services/utils/CookiesService";
import GTMService from "../../../services/utils/GTMService";
import Button from '../../button/simpleButton/Button';
import ManageCoookiesModal from "../ManageCookieModal/ManageCookieModal";
import css from './CookiesBanner.module.scss';

const namespacesRequired = ['cookie', 'common'];

const CookiesBanner = ({ t, setShowBanner }) => {

  const [showManageCookieModal, setShowManageCookieModal] = useState(false);
  const [isBannerActive, setIsBannerActive] = useState(null);


    const declineSubmit = () => {
      setShowManageCookieModal(false);
      setIsBannerActive(false);
      localStorage.setItem('cookieBanner', 'enable');
      AnalyticsService.disableAnalyticsCookie();
      AdsService.disableAdsCookie();
      CookiesService.disableMarketingCookie();
      GTMService.sendEvent("consent_update");
    };

    const agreedSubmit = () => {
        setShowManageCookieModal(false);
        setIsBannerActive(false);
        localStorage.setItem('cookieBanner', 'enable');
        AnalyticsService.enableAnalyticsCookie();
        AdsService.enableAdsCookie();
        CookiesService.enableMarketingCookie();
        GTMService.sendEvent("consent_update");
    };

  useEffect(() => {
    setIsBannerActive((localStorage.getItem('cookieBanner') !== "enable"));
  }, [isBannerActive]);

  return (
    <React.Fragment>
      {isBannerActive && (
        <div className={css.banner}>
          <ManageCoookiesModal visible={showManageCookieModal}
            onHide={() => {
              setShowManageCookieModal(false);
            }}
            onSubmit={() => {
              setShowManageCookieModal(false);
              setIsBannerActive(false);
              localStorage.setItem('cookieBanner', 'enable');
            }}
          />
          <p>
            <span dangerouslySetInnerHTML={{ __html: t('cookie:banner-desc.part-1') }} />
            <a style={{ cursor: "pointer" }} class='blue' onClick={() => setShowManageCookieModal(true)} dangerouslySetInnerHTML={{ __html: t('cookie:banner-desc.part-2') }} />
            <span dangerouslySetInnerHTML={{ __html: t('cookie:banner-desc.part-3', { link: "/static/pdf/pvp_gl_mariage_nov_2024.pdf" }) }} />
          </p>

          <div className={css.flex_container}>
            <Button className={css.btn_white} onClick={() => setShowManageCookieModal(true)}>
              {t('cookie:manage')}
            </Button>
            <Button className={css.btn_white} onClick={() => declineSubmit()}>
              {t('cookie:decline')}
            </Button>
            <Button className={css.btn_white} onClick={() => agreedSubmit()}>
              {t('cookie:agree')}
            </Button>
          </div>
          <p style={{ color: "transparent", textAlign: "right", padding: 0 }}>Is not here</p>
        </div>
      )}
    </React.Fragment>


  );
};

export default withTranslation(namespacesRequired)(CookiesBanner);

