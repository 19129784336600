/**
 * UserService
 * @module Services/domain/UserService
 * @description Offers a set of methods to help with user information
 */

import HttpService from "../http/HttpService";
import LinksProvider from '../http/LinksProvider';

const self = {};

/**
 * Gets user notifications
 * @author Paris Romain
 * @alias get
 * @param limit {number} The notification count needed
 * @param offset {number} The notification start index
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting notifications
 */
self.getNotifications = (limit, offset) => {
  const url = `${LinksProvider.get(LinksProvider.API.USERS.NOTIFICATIONS)}?limit=${limit || 4}&offset=${offset || 0}`;
  return HttpService().get(url)
    .then(result => result);
};

/**
 * Gets user account status
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting account status
 */
self.getAccountProfile = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_PROFILE))
    .then(result => result.user);
};

/**
 * Gets user account status
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting account status
 */
self.update = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USERS), values)
    .then(result => result.user);
};

/**
 * Create user rib
 * @author Paris Romain
 * @alias post
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing an user iban
 */
self.createRib = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_CREATE_RIB), values)
    .then(result => {
      if (result.is_strong_auth_resp) {
        return result;
      } else {
        return result.ribs;
      }
    });
};

/**
 * Gets user rib
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user rib
 */
self.getUserRib = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_GET_RIB))
    .then(result => result.ribs);
};

/**
 * Delete a user rib
 * @author Paris Romain
 * @alias delete
 * @param reference {string}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the deleted user rib
 */
self.deleteUserRib = (reference) => {
  return HttpService().delete(LinksProvider.get(LinksProvider.API.USERS.USER_DELETE_RIB, {reference: reference}))
    .then(result => result.ribs);
};

/**
 * update user optins
 * @author Paris Romain
 * @alias updateOptins
 * @param reference {string}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updatedUser
 */
self.updateOptins = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USER_OPTINS), values)
    .then(result => result);
};

/**
 * update user email
 * @author Paris Romain
 * @alias updateEmail
 * @param reference {string}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated email
 */
self.updateEmail = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.UPDATE_EMAIL), values)
    .then(result => result);
};

/**
 * Confirm email update
 * @author Romain Paris
 * @alias ConfirmEmailUpdate
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated email
 */
self.confirmEmailUpdate = function (ctx, reference, request) {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.CONFIRM_EMAIL_UPDATE, {reference: reference}), request)
    .then((response) => {
      return response;
    });
};


/**
 * Activates an account
 * @author Romain Paris
 * @alias resendActivateAccount
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */
self.resendActivateAccount = function (request) {
  return HttpService().get(LinksProvider.get(LinksProvider.API.AUTH.RESEND_ACTIVATE_ACCOUNT), request)
    .then((response) => {
      return response;
    });
};

/**
 * Get user spending
 * @author Romain Paris
 * @alias getUserSpending
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */
self.getUserSpending = function (ctx) {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_SPENDING))
    .then(result => result.pots);
};

/**
 * Get user spending m1l
 * @author Ghassen Manai
 * @alias getUserSpendingM1L
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */
self.getUserSpendingM1L = function (ctx) {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_SPENDING_M1L))
    .then(result => result.expenditures);
};


/**
 * Gets user pools
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user pools
 */
self.getUserPool = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_POT))
    .then(result => result.pots);
};

/**
 * Get user spending
 * @alias checkUserHasExpenditures
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */
self.checkUserHasExpenditures = function (ctx) {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_CHECK_EXPENDITURES))
    .then(result => result.hasExpenditures);
};


/**
 * Get user's spouse information
 * @author Antoine Sauvage
 * @alias getUserSpouse
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */

self.getUserSpouse = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_SPOUSE))
    .then(result => result.spouse);
};

/**
 * Creates a new spouse attachment request for the user's active list
 * @author Antoine Sauvage
 * @alias addSpouse
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */

self.addSpouse = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_SPOUSE), values)
    .then(result => result);
};

/**
 * Fetches a user's current active list
 * @alias get
 * @memberof module:Services/domain/UserService
 * @returns {promise}  pot
 */
self.getActiveList = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.GET_ACTIVE_LIST))
    .then(result => result.pot);
};


/**
 * update the user's spouse
 * @author Antoine Sauvage
 * @alias updatespouse
 * @param values {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the active list the spouse is attached to
 */
self.updateSpouse = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USER_SPOUSE), values)
    .then(result => result);
};

/**
 * update the user action spouse
 * @author Hassen Charef
 * @alias updateSpouseUserAction
 * @param values {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the spouse user action
 */
self.updateSpouseUserAction = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.SPOUSE_USER_ACTION), values)
    .then(result => result);
};


/**
 * Confirm the user's spouse
 * @author Antoine Sauvage
 * @alias confirmSpouse
 * @param reference {string}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the active list the spouse is attached to
 */
self.confirmSpouse = (reference) => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.USERS.USER_CONFIRM_SPOUSE, {reference: reference}))
    .then(result => result);
};

/**
 * @api {get} /users/get-account-activation-progress fetches a user's account validation progress
 * @apiName getAccountValidationProgress
 * @apiGroup WeddingListUser
 * @apiVersion 0.0.0
 * @apiDescription  Fetches a user's account validation progress
 */

self.getAccountValidationProgress = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.GET_ACCOUNT_VALIDATION_PROGRESS))
    .then(result => result.user);
};

/**
 * update the user's phone
 * @author Antoine Sauvage
 * @alias update phone
 * @apiParam {string} email user email
 * @apiParam {string} phone user new phone number
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated user
 */
self.updatePhone = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USER_UPDATE_PHONE), values)
    .then(result => result);
};

/**
 * update user spouse phone
 * @author Seif Khiari
 * @alias updateSpousePhone
 * @apiParam {string} useractionReference spouse confirm user action reference
 * @apiParam {string} phone user spouse new phone number
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated user
 */
self.updateSpousePhone = (userActionReference, phone) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USER_UPDATE_SPOUSE_PHONE, {reference: userActionReference}), {phone: phone})
    .then(result => result);
};

/**
 * update the user's address
 * @author Seif Khiari
 * @alias update address
 * @apiParam {Object} values values to uopate
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated user
 */
self.updateAddress = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USER_UPDATE_ADDRESS), values)
    .then(result => result);
};

/**
 * sends the user's profile data
 * @author Ghassen Manai
 * @alias send user profile data
 * @apiParam {Object} values values to update
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated user
 */
self.sendUserProfileData = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_SEND_PROFILE_DATA), values)
    .then(result => result);
};

/**
 * gets the user's self certification
 * @author Soltane Jerbi
 * @alias get user self certification
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user's self certif
 */
self.getUsersSelfCertif = () => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.USERS.USER_GET_SELF_CERTIF))
    .then(result => result);
};

/**
 * gets the user's m1l profile
 * @author Ghassen Manai
 * @alias get user m1l profile
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user's m1l profile
 */
self.getUserM1LProfile = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_GET_PROFILE_DATA))
    .then(result => result.user);
};

/**
 * get user m1l eKYC data
 * @author Hassen Charef
 * @alias getUserM1LEKycData
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing user m1l eKYC data
 */
self.getUserM1LEKycData = (ctx) => {
  return HttpService(ctx).get(LinksProvider.API.USERS.USER_GET_EKYC_DATA)
    .then(result => result);
};

/**
 * get user m1l list activation last step status
 * @author Seif Khiari
 * @alias getUserM1lLastStepInfo
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing user m1l list activation last step data
 */
self.getUserM1lLastStepInfo = (ctx) => {
  return HttpService(ctx).get(LinksProvider.API.USERS.USER_ACTIVATE_LIST_LAST_STEP_INFO)
    .then(result => result);
};

/**
 * gets the user's M1L statement for a specific month
 * @author Hassen Charef
 * @alias getUserStatement
 * @param year {number} The year of the needed statement
 * @param month {number} The month of the needed statement
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user's m1l statement
 */
self.getUserStatement = (year, month) => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.USERS.GET_STATEMENT, {year, month}))
};

/**
 * gets the user's credit card
 * @author Ghassen Manai
 * @alias getUserCreditCard
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user's credit card
 */
self.getUserCreditCard = (ctx) => {
  return HttpService(ctx).get(LinksProvider.API.USERS.USER_CREDIT_CARD)
    .then(result => result);
};

/**
 * Gets user sponsor promo code
 * @author Hassen Charef
 * @alias getUserSponsorPromoCode
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user sponsor promo code
 */
self.getUserSponsorPromoCode = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_SPONSOR_PROMO_CODE))
    .then(result => result.UserSponsorPromoCode);
};

/**
 * Gets user invited wedding lists
 * @author Seif Khiari
 * @alias getUserInvitedWeddingLists
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the wedding lists
 */
self.getUserInvitedWeddingLists = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_INVITED_WEDDING_LISTS))
    .then(result => result.pots);
};

/**
 * sends the user's declarative data
 * @author Ghassen Manai
 * @alias sendUserDeclarativeData
 * @apiParam {Object} values values to update
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated user
 */
self.sendUserDeclarativeData = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_SEND_DECLARATIVE_DATA), values)
    .then(result => result);
};

/**
 * sends the user's tax and banking data to be retained in the database
 * @author Ghassen Manai
 * @alias sendUserTaxDataForRetention
 * @apiParam {Object} values values to update
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the result of the request
 */
self.sendUserTaxDataForRetention = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_SEND_DATA_FOR_RETENTION), values)
    .then(result => result);
};


/**
 * Updates the M1L KYC step for the user
 * @author Ghassen Manai
 * @alias updateM1lKycStep
 * @apiParam {Number} newStep the value of the step to move the user to
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the result of the request
 */
self.updateM1lKycStep = (newStep) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_UPDATE_M1L_KYC_STEP), {m1l_kyc_step: newStep})
    .then(result => result);
};

/**
 * Updates the M1L KYC step for the user
 * @author Ahmed TOUAITI
 * @alias getStrongAuthData
 * @apiParam {Number} newStep the value of the step to move the user to
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the result of the request
 */
self.getStrongAuthData = (newStep) => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.USERS.USER_STRONG_AUTH_DATA));
};


/**
 * sends the user choice of kyc process
 * @author Amine Elouaer
 * @alias kycProcessChoice
 * @apiParam {Object} values values to update
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the result of the request
 */
self.kycProcessChoice = (process) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_KYC_PROCESS_CHOICE), {"xpollens_kyc_verification_method": process})
};



/**
 * sends the user's declarative cgu
 * @author Ahmed TOUAITI
 * @alias sendUserDeclarativeData
 * @apiParam {}
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the updated auth results
 */
self.sendUserDeclarativeCgu = () => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_SEND_DECLARATIVE_CGU))
    .then(result => result);
};

export default self;
