import React, {useState} from 'react';
import css from './ToggleRadio.module.scss';

const ToggleRadio = ({name, action, label, trueContent, falseContent, disabled = undefined, checked}) => {

    const [isChecked, setIsChecked] = useState(checked);

    return (
        <label htmlFor={name}
               className={css.label}
        >
            <h3>{label}</h3>
            <div className={`${css.toggle} ${isChecked ? css.reverse : ''}`}>
                <input type="checkbox"
                       name={name}
                       id={name}
                       className={css.hidden}
                       onChange={() => {
                           action && action();
                           setIsChecked(!isChecked);
                       }}
                       checked={checked}
                       disabled={disabled}
                />
                <span className={css.switch}/>
                <span className={css.content}>{isChecked ? trueContent : falseContent}</span>
            </div>

        </label>
    );
};

export default ToggleRadio;

