import moment from "moment";

const stringNormalize = require('string-normalize-es6');

export const formatUrl = (val) => {
    if (val === undefined) {
        return;
    }
    if (val.length > 1) {
        const custom_url = stringNormalize(val).replace(/([^a-z0-9/\s\-])+/gi, '').replace(/[\s\-]+/g, '-');
        return custom_url.toLowerCase();
    }
};


export const convertDate = (date) => {
    return moment(date).locale("fr").format("DD MMMM YYYY")
};


// Percentage for CardItem Progress bar
export const calculPercentage = (collected, totalPrice) => {
    return Math.floor((collected / totalPrice) * 100);
};