/**
 * CurrencyService
 * @module Services/utils/CurrencyService
 * @description Offers utility functions to handle currencies
 */


const self = {};


/**
 * Converts cents to euro
 * @author Sameh Bellez
 * @alias centsToEuro
 * @memberof module:Services/utils/CurrencyService
 * @param   {number} amount The amount in cents
 * @returns {number}  The amount in euros
 */
self.centsToEuro = (amount) => {
  const res = (amount / 100).toFixed(2);

  return res.toString()
      .replace('.00', '')
      .replace(',00', '')
      .replace('.', ',');
};

/**
 * Converts euros string to cents
 * @author Seif Khiari
 * @alias eurosToCents
 * @name CurrencyService.eurosToCents
 * @memberof module:Services/CurrencyService
 * @param   {number || string} amount The un-formatted float amount
 * @return  {number} the amount in cents
 */
self.eurosToCents = (amount) => {
  return parseInt(parseFloat(`${amount}` * 100).toPrecision(12));
};


export default self;
